import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';
import * as actionTypes from './actionTypes';


//----------------------------------------------------------------------------------------------------------------------

export const getMe = () => ({
  type             : actionTypes.GET_ME,
  [WAIT_FOR_ACTION]: actionTypes.GET_ME_SUCCESS,
  [ERROR_ACTION]   : actionTypes.GET_ME_ERROR,
});

export const getMeSuccess = (account = {}) => ({
  type   : actionTypes.GET_ME_SUCCESS,
  payload: {
    account,
  },
});

export const getMeError = (error) => ({
  type: actionTypes.GET_ME_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const signInCallback = (account) => ({
  type   : actionTypes.SIGN_IN_CALLBACK,
  payload: {
    account,
  },
});

export const loadAccount = () => ({
  type: actionTypes.LOAD_ACCOUNT,
});

export const loadAccountSuccess = (account = {}) => ({
  type   : actionTypes.LOAD_ACCOUNT_SUCCESS,
  payload: {
    account,
  },
});

export const loadAccountError = (error) => ({
  type: actionTypes.LOAD_ACCOUNT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------

export const signOut = ({ preserveStorage } = {}) => ({
  type   : actionTypes.SIGN_OUT,
  payload: {
    preserveStorage,
  },
});

export const signOutSuccess = () => ({
  type: actionTypes.SIGN_OUT_SUCCESS,
});

export const signOutError = (error) => ({
  type: actionTypes.SIGN_OUT_ERROR,
  error,
});

//----------------------------------------------------------------------------------------------------------------------
