import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { useAction } from 'hooks';
import App from 'modules/App';


type Messages = { [item: string]: MessageDescriptor | string }

interface TabCommonProps {
  activeItem: string,
  itemsData?: TabItemsAdditionalData,
  messages: Messages
  errors?: { [item: string]: object },
  modified?: string[],
  action: (item: string) => void,
}

interface TabProps extends TabCommonProps {
  item: string,
}

interface Props extends TabCommonProps {
  items: string[],
}


const getLabel = (item: string, messages: Messages) => {
  if (!messages || !messages[item]) return item;
  if (isString(messages[item])) return messages[item];
  return <FormattedMessage {...messages[item] as MessageDescriptor} />;
};


const Tab: FC<TabProps> = ({ item, activeItem, itemsData, messages, errors, modified, action }) => {
  const isNavigationBlocked = useSelector(App.selectors.isNavigationBlocked);
  const openModal = useAction(App.actions.openModal, App.constants.UNSAVED_CHANGES_MODAL);
  const label = getLabel(item, messages);
  const counter = get(itemsData, [item, 'counter']);
  const counterChip = isNumber(counter) ? <span className="tab__counter">{ counter }</span> : null;

  const onClick = (evt) => {
    evt.preventDefault();
    if (isNavigationBlocked) {
      openModal();
      return;
    }
    action(item);
  };

  return (
    <li
      className={
        cn('tab', {
          'tab--active': item === activeItem,
          'tab--error' : errors && errors[item],
        })
      }
    >
      <a href="" onClick={onClick}>
        { label }{ modified && modified[item] && '*' }{ counterChip }
      </a>
    </li>
  );
};


const Tabs: FC<Props> = ({ items, ...props }) => {
  const renderTab = (item) => <Tab key={item} item={item} {...props} />;

  return (
    <div className="tabs">
      { items.map(renderTab) }
    </div>
  );
};

export default Tabs;
