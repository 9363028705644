import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import Select from 'components/Form/Select';
import FormContainerAbstract from 'components/FormContainerAbstract';
import App from 'modules/App';
import * as actions from '../../../actions';
import * as shapes from '../../../shapes';
import messages from '../../../messages';
import * as constants from '../../../constants';
import validatorRules from './validatorRules.json';
import styles from './AddCountryForm.pcss';


class AddCountryForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    languageOptions: PropTypes.arrayOf(shapes.language),
    // Explicit actions
    onClose        : PropTypes.func,
    // Implicit props
  };


  constructor(props) {
    super(props);
    this.validatorRules = { ...validatorRules };
  }


  get languageOptions() {
    return this.props.languageOptions.sort((a, b) => {
      if (a.englishName > b.englishName) { return 1; }
      return -1;
    });
  }

  renderHeader() {
    return (
      <div>
        <p className="mb-6"><FormattedMessage {...messages.infos.addNewCountry} /></p>
      </div>
    );
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">
          <Button
            type="reset"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block"
            isInProgress={this.props.isInProgress}
            onClick={this.props.onClose}
          />
        </div>
        <div className="col-6">
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={messages.buttons.addCountry}
            className="btn--block btn--filled"
            isDisabled={this.isDisabled}
            isInProgress={this.props.isInProgress}
          />
        </div>
      </div>
    );
  }


  renderForm() {
    return (
      <Form
        className={styles.addCountryForm}
        onSubmit={() => this.onSubmit()}
      >
        <div className="row">
          <FormGroup
            id="countryName"
            className="col-6"
            labelMessage={messages.labels.countryName}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.countryName}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
          <FormGroup
            id="language"
            className="col-6"
            labelMessage={messages.labels.language}
            formValues={this.props.formValues}
          >
            <Select
              optionsFrom={this.languageOptions}
              valueKey="code"
              labelKey="englishName"
              noValueMessage={messages.placeholders.language}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
        <div className="row mb-6">
          <FormGroup
            id="countryCode"
            className="col-6"
            labelMessage={messages.labels.countryCode}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.countryCode}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
        { this.renderActions() }
      </Form>
    );
  }


  render() {
    return (
      <div>
        { this.renderHeader() }
        { this.renderForm() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues: App.selectors.formSelector(constants.ADD_COUNTRY_FORM)(state),

});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.ADD_COUNTRY_FORM;
  return {
    onSubmit        : (values) => dispatch(actions.addCountry(values)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedAddCountryForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCountryForm);


export default withStyles(styles)(ConnectedAddCountryForm);
