import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import Button from 'components/Form/Button';
import App from 'modules/App';
import styles from './StandardActions.pcss';


interface Props {
  hasChanges: boolean,
  isInProgress: boolean,
  infoMessage?: MessageDescriptor,
}


const StandardActions: FC<Props> = ({ hasChanges, isInProgress, infoMessage }) => {
  useStyles(styles);

  return (
    <div className={styles.standardActions}>
      <Button
        type="reset"
        styleModifier="primary"
        labelMessage={App.messages.buttons.cancel}
        isDisabled={!hasChanges || isInProgress}
        className="btn"
      />
      <Button
        type="submit"
        styleModifier="primary"
        labelMessage={App.messages.buttons.save}
        className="btn btn--filled"
        isDisabled={!hasChanges}
        isInProgress={isInProgress}
      />
      { infoMessage && <p><FormattedMessage {...infoMessage} /></p> }
    </div>
  );
};

export default StandardActions;
