import { useEffect, useRef } from 'react';


export const useProgressSuccess = (isInProgress: boolean, hasErrors: boolean, onSuccess: () => void) => {
  const prevIsInProgressRef = useRef<boolean>(isInProgress);

  useEffect(() => {
    if (prevIsInProgressRef.current !== isInProgress) {
      if (!isInProgress && !hasErrors) {
        onSuccess();
      }
      prevIsInProgressRef.current = isInProgress;
    }
  }, [isInProgress]);
};
