import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import App from 'modules/App';
import { useAction } from './useAction';


export const useBlockNavigation = (hasChanges) => {
  // isNavigationBlocked is used by UnsavedChangesModal and Tabs
  const isNavigationBlocked = useSelector(App.selectors.isNavigationBlocked);
  const blockNavigation = useAction(App.actions.blockNavigation);
  const unblockNavigation = useAction(App.actions.unblockNavigation);

  useEffect(() => {
    if (hasChanges) {
      if (!isNavigationBlocked) blockNavigation();
    } else if (isNavigationBlocked) {
      unblockNavigation();
    }
  }, [hasChanges]);

};
