import { registerAction, unregisterAction } from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  isAuthenticated: false,
  account        : null,
  fetching       : [],
  sending        : [],
  errors         : [],
};


export default function reducer(state = { ...initialState }, action = {}) {

  switch (action.type) {

    case actionTypes.GET_ME: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.GET_ME),
        errors  : unregisterAction(state.errors, actionTypes.GET_ME),
      };
    }
    case actionTypes.GET_ME_SUCCESS: {
      const {
        account,
      } = action.payload;
      return {
        ...state,
        account,
        fetching: unregisterAction(state.fetching, actionTypes.GET_ME),
      };
    }
    case actionTypes.GET_ME_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.GET_ME),
        errors  : registerAction(state.errors, actionTypes.GET_ME),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SIGN_IN_CALLBACK: {
      const {
        account,
      } = action.payload;
      return {
        ...state,
        isAuthenticated: !!account,
        account,
      };
    }
    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.LOAD_ACCOUNT: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.LOAD_ACCOUNT),
        errors  : unregisterAction(state.errors, actionTypes.LOAD_ACCOUNT),
      };
    }
    case actionTypes.LOAD_ACCOUNT_SUCCESS: {
      const {
        account,
      } = action.payload;
      return {
        ...state,
        account,
        isAuthenticated: !!account,
        fetching       : unregisterAction(state.fetching, actionTypes.LOAD_ACCOUNT),
      };
    }
    case actionTypes.LOAD_ACCOUNT_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.LOAD_ACCOUNT),
        errors  : registerAction(state.errors, actionTypes.LOAD_ACCOUNT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SIGN_OUT: {
      return {
        ...state,
        isAuthenticated: false,
        sending        : registerAction(state.sending, actionTypes.SIGN_OUT),
        errors         : unregisterAction(state.errors, actionTypes.SIGN_OUT),
      };
    }
    case actionTypes.SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }
    case actionTypes.SIGN_OUT_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SIGN_OUT),
        errors : registerAction(state.errors, actionTypes.SIGN_OUT),
      };
    }

    //------------------------------------------------------------------------------------------------------------------


    default: {
      return state;
    }
  }
}
