
import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import MiniSearch from 'minisearch';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { filter, find, findIndex, includes, intersection } from 'lodash';
import DeleteIcon from 'svg/delete.svg';
import AddIcon from 'svg/add.svg';
import Loupe from 'svg/loupe-light.svg';
import Input from 'components/Form/Input';
import Table from 'components/Table';
import Tag from 'components/Tag';
import Button from 'components/Form/Button';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import Account from 'modules/Account';
import App from 'modules/App';
import { SCOPE_NAMES } from 'modules/Account/constants';
import messages from '../../../../../messages';
import * as constants from '../../../../../constants';
import * as actions from '../../../../../actions';
import * as selectors from '../../../../../selectors';
import styles from './IdentifierTermTable.pcss';


class IdentifierTermTable extends React.Component {

  static propTypes = {
    // Explicit props
    formValues                    : PropTypes.object.isRequired,
    onSetFormValue                : PropTypes.func.isRequired,
    onSetIdentifierOptionFormValue: PropTypes.func.isRequired,
    onOpenUpdateOrAddOptionModal  : PropTypes.func.isRequired,
    onCloseUpdateOrAddOptionModal : PropTypes.func.isRequired,
    onSetUpdateOrAddIdentifierTerm: PropTypes.func.isRequired,
    // Implicit props
    updateOrAddIdentifierTerm     : PropTypes.object,
  };


  constructor(props) {
    super(props);

    this.state = {
      activeFilterTags  : [],
      isSearchProcessing: false,
      miniSearch        : new MiniSearch({
        idField      : 'termKey',
        fields       : ['termKey', 'labelValue'],
        storeFields  : ['termKey', 'labelValue', 'slaveRegExp', 'identifierNames'],
        searchOptions: {
          fuzzy : 0,
          prefix: true,
        },
      }),
    };
  }


  static getDerivedStateFromProps(props, state) {
    const entities = get(props.formValues, 'values.terms', []);
    state.miniSearch.removeAll();
    state.miniSearch.addAll(entities);
    return state;
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.search !== this.state.search) {
      this.onSearchFinish();
    }
    if (this.props.updateOrAddIdentifierTerm
        && prevProps.updateOrAddIdentifierTerm !== this.props.updateOrAddIdentifierTerm) {
      this.onUpdateOrAddIdentifierTerm();
    }
  }


  onOpenAddOptionModal(term) {
    this.props.onSetIdentifierOptionFormValue(term);
    this.props.onOpenUpdateOrAddOptionModal();
  }


  onUpdateOrAddIdentifierTerm() {
    const { updateOrAddIdentifierTerm } = this.props;
    this.props.onCloseUpdateOrAddOptionModal();
    let terms = get(this.props.formValues, 'values.terms', []);
    const index = findIndex(terms, (term) => term.termKey === updateOrAddIdentifierTerm.termKey);
    if (index !== -1) {
      terms[index] = updateOrAddIdentifierTerm;
    } else {
      terms = [...terms, updateOrAddIdentifierTerm];
    }
    this.props.onSetFormValue(
      {
        id   : 'terms',
        value: terms,
      },
    );
    this.props.onCloseUpdateOrAddOptionModal();
    this.props.onSetUpdateOrAddIdentifierTerm(null);
  }


  onClickDelete(termToDelete) {
    let terms = get(this.props.formValues, 'values.terms', []);
    terms = filter(terms, (term) => term.termKey !== termToDelete.termKey);
    this.props.onSetFormValue(
      {
        id   : 'terms',
        value: terms,
      },
    );
  }


  onFilterTagClick(tag) {
    this.setState(
      (state) => {
        let { activeFilterTags } = state;
        activeFilterTags = find(activeFilterTags, (activeFilterTag) => activeFilterTag === tag)
          ? filter(activeFilterTags, (activeFilterTag) => activeFilterTag !== tag)
          : [...activeFilterTags, tag];
        return (
          {
            activeFilterTags,
          }
        );
      },
    );
  }


  onSearchFinish() {
    this.setState((state) => ({ isSearchProcessing: state.isSearchProcessing }));
  }


  get entities() {
    let entities = get(this.props.formValues, 'values.terms', []);
    const { activeFilterTags, search } = this.state;
    if (search) {
      entities = this.state.miniSearch.search(search);
    }
    if (activeFilterTags.length) {
      entities = filter(entities, (entity) => intersection(entity.identifierNames, activeFilterTags).length);
    }
    return entities;
  }


  get schema() {
    return [
      {
        key         : 'termKey',
        labelMessage: messages.identifierTab.labels.termKey,
        renderer    : (term) => (
          <b>{ term.termKey }</b>
        ),
      },
      {
        key         : 'labelValue',
        labelMessage: messages.identifierTab.labels.name,
        renderer    : (term) => (
          <p>{ term.labelValue }</p>
        ),
      },
      {
        key         : 'personalIdentifier',
        labelMessage: messages.identifierTab.labels.personalIdentifier,
        renderer    : (term) => (
          this.renderCheck(term, constants.IDENTIFIER_TERM_TYPES.PERSONAL)
        ),
      },
      {
        key         : 'licence',
        labelMessage: messages.identifierTab.labels.licence,
        renderer    : (term) => (
          this.renderCheck(term, constants.IDENTIFIER_TERM_TYPES.LICENCE)
        ),
      },
      {
        key         : 'organizationIdentifier',
        labelMessage: messages.identifierTab.labels.organizationIdentifier,
        renderer    : (term) => (
          this.renderCheck(term, constants.IDENTIFIER_TERM_TYPES.ORGANIZATION)
        ),
      },
      {
        key     : 'actions',
        renderer: (term) => (
          this.renderActions(term)
        ),
      },
    ];
  }


  get filterTagsIds() {
    return [constants.IDENTIFIER_TERM_TYPES.PERSONAL,
      constants.IDENTIFIER_TERM_TYPES.LICENCE,
      constants.IDENTIFIER_TERM_TYPES.ORGANIZATION];
  }


  renderCheck(term, termType) {
    return (
      <CheckboxRadio
        key={term.termKey}
        inputValue="true"
        isDisabled
        onChange={(input) => this.props.onSetFormValue(input)}
        value={includes(term.identifierNames, termType) ? 'true' : 'false'}
      />
    );
  }


  renderActions(term) {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          labelMessage={messages.buttons.config}
          className="btn--outline mr-5"
          type="button"
          onClick={() => this.onOpenAddOptionModal(term)}
        />
        <DeleteIcon
          className={styles.deleteIcon}
          onClick={() => this.onClickDelete(term)}
        />
      </div>
    );
  }


  renderTable() {
    return (
      <Table
        idKey="termKey"
        schema={this.schema}
        entities={this.entities}
        page={0}
        perPage={10}
        isPerPageOff
      />
    );
  }


  renderHeader() {
    return (
      <div>
        <h5 className="text--h5 mt-0 mb-2"><FormattedMessage {...messages.headers.nationalIdentifiersRecords} /></h5>
        <p className="text--paragraph mb-7"><FormattedMessage {...messages.infos.nationalIdentifiersRecords} /></p>
      </div>
    );
  }


  renderTag(type) {
    return (
      <Tag
        id={type}
        key={type}
        titleMessage={messages.identifierTab.labels[type]}
        isActive={!!find(this.state.activeFilterTags, (activeFilterTag) => activeFilterTag === type)}
        onClick={() => this.onFilterTagClick(type)}
      />
    );
  }


  renderFilters() {
    return (
      <div className={styles.header}>
        <div className={cn(styles.header_side, 'd-flex align-items-center')}>
          {
            this.filterTagsIds.map((filterTagsId) => (
              this.renderTag(filterTagsId)
            ))
          }
        </div>
        <div className={cn(styles.header_side, 'd-flex align-items-center')}>
          { this.renderSearch() }
          { this.renderAddNewTermButton() }
        </div>
      </div>
    );
  }


  renderSearch() {
    return (
      <div className={styles.header__search}>
        <Input
          placeholder={messages.placeholders.searchIdentifier}
          value={this.state.search}
          onChange={
            (input) => this.setState({ isSearchProcessing: true,
              search            : input.value })
          }
        />
        <Loupe className={styles.search__loupe} />
      </div>
    );
  }


  renderAddNewTermButton() {
    return (
      <Button
        type="button"
        styleModifier="primary"
        className={cn(styles.header__addNewTerm, 'btn--filled')}
        onClick={() => this.onOpenAddOptionModal(null)}
      >
        <AddIcon className={styles.addButton__icon} />
        <p><FormattedMessage {...App.messages.buttons.addNew} /></p>
      </Button>
    );
  }


  render() {
    return (

      <div className="mt-7">
        { this.renderHeader() }
        { this.renderFilters() }
        { this.renderTable() }
      </div>
    );
  }

}

const mapStateToProps = (state) => (
  {
    isSDNAdmin               : Account.selectors.scope(state) === SCOPE_NAMES.SDN_ADMIN,
    updateOrAddIdentifierTerm: selectors.updateOrAddIdentifierTerm(state),
  });


const mapDispatchToProps = (dispatch) => ({
  onSetIdentifierOptionFormValue: (initialFormValue) => dispatch(
    actions.setIdentifierOptionFormValue(initialFormValue),
  ),
  onSetUpdateOrAddIdentifierTerm: (value) => {
    dispatch(actions.setUpdateOrAddIdentifierTerm(value));
  },
  onOpenUpdateOrAddOptionModal : () => dispatch(App.actions.openModal(constants.ADD_IDENTIFIER_OPTION_MODAL)),
  onCloseUpdateOrAddOptionModal: () => dispatch(App.actions.closeModal(constants.ADD_IDENTIFIER_OPTION_MODAL)),
});


const ConnectedIdentifierTermTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(IdentifierTermTable);


export default withStyles(styles)(ConnectedIdentifierTermTable);

