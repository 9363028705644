import { put, takeLatest, call, select } from 'redux-saga/effects';
import includes from 'lodash/includes';
import set from 'lodash/set';
import unset from 'lodash/unset';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import camelCase from 'lodash/camelCase';
import forOwn from 'lodash/forOwn';
import { flatten } from 'helpers/transformers';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import Account from 'modules/Account';
import { ACCOUNT_SCOPE_NAMES } from 'modules/Account/constants';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as selectors from './selectors';
import messages from './messages';


function* fetchCountries() {
  try {
    const requestUrl = '/api/Country';
    const countries = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchCountriesSuccess(countries));
  } catch (err) {
    yield put(actions.fetchCountriesError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchAllCountries() {
  try {
    const requestUrl = '/api/Country/All';
    const countries = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchAllCountriesSuccess(countries));
  } catch (err) {
    yield put(actions.fetchAllCountriesError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchLanguages() {
  try {
    const requestUrl = '/api/Language';
    const languages = yield call(ApiService.originalRequest, requestUrl);

    yield put(actions.fetchLanguagesSuccess(languages));
  } catch (err) {
    yield put(actions.fetchLanguagesError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* addCountry({ payload }) {
  try {
    const { countryCode, countryName, language } = payload;
    const requestUrl = '/api/Country';
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : {
        countryCode,
        defaultLanguageCode: language,
        englishName        : countryName,
      },
    });
    yield put(actions.addCountrySuccess(countryCode, language, countryName));
  } catch (err) {
    yield put(actions.addCountryError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* enableCountry({ payload }) {
  try {
    const { countryCode } = payload;

    const requestUrl = `/api/Country/${countryCode}/Enable`;
    yield call(ApiService.originalRequest, requestUrl, {
      method: 'PUT',
      body  : {
        countryCode,
      },
    });
    yield put(actions.enableCountrySuccess(null));
  } catch (err) {
    const validationErrors = get(err, 'validationErrors', null);

    if (validationErrors) {
      yield put(actions.enableCountryError(validationErrors));
    } else {
      yield put(actions.enableCountryError(null));
      yield call(App.dispatchError, err, messages);
    }
  }
}


function* deleteCountry({ payload }) {
  try {
    const { countryCode } = payload;
    const requestUrl = `/api/Country/${countryCode}`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'DELETE',
    });
    yield put(actions.deleteCountrySuccess());
  } catch (err) {
    yield put(actions.deleteCountryError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchCountry({ payload }) {
  try {
    const requestUrl = `/api/Country/${payload.countryCode}`;
    const country = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchCountrySuccess(country));
  } catch (err) {
    yield put(actions.fetchCountryError(err));
    yield call(App.dispatchError, err, messages);
  }
}

function getGlucoseRangesToDisplay(glucoseRanges) {
  const result = Object.assign({}, glucoseRanges);
  const entities = flatten(glucoseRanges);
  forOwn(entities, (value, key) => {
    set(result, `${key}-toDisplay`, value ? toNumber(value.toFixed(1)) : value);
  });
  return result;
}


function* fetchCountryConfiguration({ payload }) {
  try {
    const requestUrl = `/api/Country/${payload.countryCode}/Configuration`;
    const configuration = yield call(ApiService.originalRequest, requestUrl);
    const { scopes } = configuration.generalSettings;
    set(configuration, 'generalSettings.scopes',
      {
        professional: includes(scopes, ACCOUNT_SCOPE_NAMES.PROFESSIONAL),
        clinicStaff : includes(scopes, ACCOUNT_SCOPE_NAMES.CLINIC_STAFF),
        personal    : includes(scopes, ACCOUNT_SCOPE_NAMES.PERSONAL),
        caregiver   : includes(scopes, ACCOUNT_SCOPE_NAMES.CAREGIVER),
      });
    set(configuration, 'generalSettings.isPrivateCloud', !get(configuration, 'generalSettings.isCentralStorageFixed'));
    unset(configuration, 'generalSettings.isCentralStorageFixed');

    set(configuration, 'glucoseRanges', getGlucoseRangesToDisplay(configuration.glucoseRanges));
    yield put(actions.fetchCountryConfigurationSuccess(configuration));
  } catch (err) {
    yield put(actions.fetchCountryConfigurationError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* updateCountryConfigurationGeneral({ payload }) {
  try {
    const { configurationGeneral } = payload;
    const scopes = [];
    forEach(ACCOUNT_SCOPE_NAMES, (accountScope) => {
      const scope = camelCase(accountScope);
      const scopeStatus = get(configurationGeneral, `scopes.${scope}`);
      if (scopeStatus) {
        scopes.push(scope);
      }
    });
    set(configurationGeneral, 'scopes', scopes);
    set(configurationGeneral, 'isCentralStorageFixed', !get(configurationGeneral, 'isPrivateCloud'));
    delete configurationGeneral.isPrivateCloud;
    if (get(configurationGeneral, 'callingCode') === null) {
      set(configurationGeneral, 'callingCode', '');
    }
    const scope = yield select(Account.selectors.scope);
    if (scope !== Account.constants.SCOPE_NAMES.SDN_ADMIN) {
      // delete configurationGeneral.callingCode;
      delete configurationGeneral.isMFAEmailEnabled;
      delete configurationGeneral.isMFASMSEnabled;
    }
    const countryCode = yield select(selectors.configuredCountryCode);
    const requestUrl = `/api/Country/${countryCode}/Configuration/GeneralSettings`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { ...configurationGeneral },
    });
    yield put(actions.updateCountryConfigurationGeneralSuccess());
  } catch (err) {
    yield put(actions.updateCountryConfigurationGeneralError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* updateCountryConfigurationUnitsOfMeasurements({ payload }) {
  try {
    const { unitMeasurements } = payload;
    const countryCode = yield select(selectors.configuredCountryCode);
    const requestUrl = `/api/Country/${countryCode}/Configuration/UnitMeasurements`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { ...unitMeasurements },
    });
    yield put(actions.updateCountryConfigurationUnitsOfMeasurementsSuccess());
  } catch (err) {
    yield put(actions.updateCountryConfigurationUnitsOfMeasurementsError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* updateCountryConfigurationGlucoseRanges({ payload }) {
  try {
    const { glucoseRanges } = payload;
    const countryCode = yield select(selectors.configuredCountryCode);
    const requestUrl = `/api/Country/${countryCode}/Configuration/GlucoseRanges`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { ...glucoseRanges },
    });
    yield put(actions.updateCountryConfigurationGlucoseRangesSuccess());
  } catch (err) {
    yield put(actions.updateCountryConfigurationGlucoseRangesError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* updateCountryConfigurationPayers({ payload }) {
  try {
    const { payers } = payload;
    set(payers, 'isPayerMandatory', false);
    const countryCode = yield select(selectors.configuredCountryCode);
    const requestUrl = `/api/Country/${countryCode}/Configuration/Payers`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { ...payers },
    });
    yield put(actions.updateCountryConfigurationPayersSuccess());
  } catch (err) {
    yield put(actions.updateCountryConfigurationPayersError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* updateCountryConfigurationIdentifiers({ payload }) {
  try {
    const countryCode = yield select(selectors.configuredCountryCode);
    const requestUrl = `/api/Country/${countryCode}/Configuration/Identifiers`;
    const { identifiers } = payload;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { ...identifiers },
    });
    yield put(actions.updateCountryConfigurationIdentifiersSuccess());
  } catch (err) {
    yield put(actions.updateCountryConfigurationIdentifiersError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* updateCountryConfigurationMeters({ payload }) {
  try {
    const { devices } = payload;
    const countryCode = yield select(selectors.configuredCountryCode);
    const requestUrl = `/api/Country/${countryCode}/Configuration/Meters`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { ...devices },
    });
    yield put(actions.updateCountryConfigurationMetersSuccess());
  } catch (err) {
    yield put(actions.updateCountryConfigurationMetersError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* sagas() {
  yield takeLatest(actionTypes.FETCH_COUNTRIES, fetchCountries);
  yield takeLatest(actionTypes.FETCH_ALL_COUNTRIES, fetchAllCountries);
  yield takeLatest(actionTypes.FETCH_LANGUAGES, fetchLanguages);
  yield takeLatest(actionTypes.ADD_COUNTRY, addCountry);
  yield takeLatest(actionTypes.ENABLE_COUNTRY, enableCountry);
  yield takeLatest(actionTypes.DELETE_COUNTRY, deleteCountry);
  yield takeLatest(actionTypes.FETCH_COUNTRY, fetchCountry);
  yield takeLatest(actionTypes.FETCH_COUNTRY_CONFIGURATION, fetchCountryConfiguration);
  yield takeLatest(actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL, updateCountryConfigurationGeneral);
  yield takeLatest(actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS,
    updateCountryConfigurationUnitsOfMeasurements);
  yield takeLatest(actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES, updateCountryConfigurationGlucoseRanges);
  yield takeLatest(actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS, updateCountryConfigurationPayers);
  yield takeLatest(actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS, updateCountryConfigurationIdentifiers);
  yield takeLatest(actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS, updateCountryConfigurationMeters);
}


export default [
  sagas,
];
