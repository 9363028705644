import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import PerPageSelect from './PerPageSelect';
import Pages from './Pages';
import styles from './Pagination.pcss';


class Pagination extends React.PureComponent {

  static propTypes = {
    // Explicit props
    perPage        : PropTypes.number,
    page           : PropTypes.number,
    totalPages     : PropTypes.number,
    pagesSlots     : PropTypes.number,
    isPerPageOff   : PropTypes.bool,
    // Explicit actions
    onPerPageChange: PropTypes.func,
    onPageChange   : PropTypes.func,
  };


  static defaultProps = {
    pagesSlots  : 5,
    isPerPageOff: false,
  };


  renderPerPageSelect() {
    const { perPage, onPerPageChange, isPerPageOff } = this.props;
    if (isPerPageOff) {
      return null;
    }
    return (
      <div className="perPageSelect">
        <PerPageSelect
          value={perPage}
          onChange={onPerPageChange}
        />
      </div>
    );
  }


  render() {
    const { totalPages, page, pagesSlots, isPerPageOff } = this.props;
    if (isPerPageOff && totalPages <= 1) {
      return null;
    }
    return (
      <div className={styles.pagination}>
        { this.renderPerPageSelect() }
        {
          totalPages > 1
            && (
              <Pages
                totalPages={totalPages}
                activePage={page}
                slotsNo={pagesSlots}
                onChange={this.props.onPageChange}
              />
            )
        }
      </div>
    );

  }

}

export default withStyles(styles)(Pagination);
