import React, { FC } from 'react';
import { MessageDescriptor } from 'react-intl';
import Button from 'components/Form/Button';
import App from 'modules/App';


interface Props {
  entity: unknown,
  labelMessage?: MessageDescriptor,
  onAction: (entity: unknown) => void,
}


const ActionButton: FC<Props> = ({ entity, labelMessage, onAction }) => {
  const onClick = () => onAction(entity);
  return (
    <Button
      labelMessage={labelMessage || App.messages.buttons.edit}
      className="btn--outline"
      onClick={onClick}
    />
  );
};

export default ActionButton;
