import { useCallback, useEffect, useState } from 'react';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import keysIn from 'lodash/keysIn';
import pick from 'lodash/pick';
import startsWith from 'lodash/startsWith';
import uniq from 'lodash/uniq';
import DefaultValidator from 'libs/Validator';
import { validateForm } from 'helpers/form';
import { useAction } from 'hooks/useAction';
import * as actions from './actions';


export const useIsFormNotCompleted = (formValues, validatorRules, {
  Validator = DefaultValidator,
} = {}) => {
  const [isFormNotCompleted, setIsFormNotCompleted] = useState(true);

  useEffect(() => {
    const requiredRules = {};
    const fields = keysIn(validatorRules);
    forEach(fields, (field) => {
      if (startsWith(validatorRules[field], 'required')) {
        requiredRules[field] = 'required';
      }
    });
    const { errors } = validateForm(formValues, requiredRules, false, Validator);
    setIsFormNotCompleted(!!errors);
  }, [formValues, validatorRules]);

  return isFormNotCompleted;
};


// TODO: Specify validatedValues type
export const useFormProcessing = (formName: string, onSubmit: (validatedValues: object) => void, {
  isToUnfold = false,
  onlyChanged = false,
  Validator = DefaultValidator,
} = {}) => {
  const onProcessing = useAction(actions.startFormProcessing, formName);
  const onErrors = useAction(actions.setFormErrors, formName);
  return useCallback((formValues, validatorRules) => {
    onProcessing();
    const { validatedValues, errors } = validateForm(formValues, validatorRules, isToUnfold, Validator);
    onErrors(errors);
    let values = validatedValues;
    if (onlyChanged) {
      const originalValues = get(formValues, 'originalValues', {});
      const changedValuesKeys = uniq(keysIn(originalValues).map((k) => k.split('.')[0]));
      values = pick(validatedValues, changedValuesKeys);
    }
    if (!errors) onSubmit(values);
  }, [onSubmit]);
};
