import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';
import { AppContext } from 'context';
import history from 'helpers/history';
import ChevronRight from 'svg/chevron-right.svg';
import Chip from 'components/Chip';
import Button from 'components/Form/Button';
import Tabs from 'components/Tabs';
import App from 'modules/App';
import Account from 'modules/Account';
import { SCOPE_NAMES } from 'modules/Account/constants';
import * as actions from '../../actions';
import * as shapes from '../../shapes';
import messages from '../../messages';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import EnableCountryModal from './EnableCountryModal';
import UnitsOfMeasurements from './tabs/UnitsOfMeasurements';
import General from './tabs/General';
import GlucoseRanges from './tabs/GlucoseRanges';
import Payers from './tabs/Payers';
import Identifiers from './tabs/Identifiers';
import Meters from './tabs/Meters';
import styles from './ConfigureCountry.pcss';


class ConfigureCountry extends React.Component {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    countryCode                               : PropTypes.string.isRequired,
    // Implicit props
    country                                   : shapes.country,
    enableCountryErrors                       : PropTypes.array,
    openModalId                               : PropTypes.string,
    isFetchInProgress                         : PropTypes.bool,
    isSendCountryConfigurationAnyTabInProgress: PropTypes.bool,
    isEnableCountryProgress                   : PropTypes.bool,
    isSDNAdmin                                : PropTypes.bool,
    isNavigationBlocked                       : PropTypes.bool,
    // Implicit actions
    onFetchCountry                            : PropTypes.func,
    onFetchCountryConfiguration               : PropTypes.func,
    onFetchLanguages                          : PropTypes.func,
    onFetchDevices                            : PropTypes.func,
    onBlockNavigation                         : PropTypes.func,
    onUnblockNavigation                       : PropTypes.func,
    onOpenUnsavedChangesModal                 : PropTypes.func,
    onOpenEnableCountryModal                  : PropTypes.func,
    onCloseModal                              : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      activeViewKey: this.viewKeysOrder[0],
      isLoaded     : false,
    };
  }


  componentDidMount() {
    this.props.onFetchCountry(this.props.countryCode);
    this.props.onFetchCountryConfiguration(this.props.countryCode);
    this.props.onFetchLanguages();
    this.props.onFetchDevices();
  }


  componentDidUpdate(prevProps) {
    if (prevProps.isSendCountryConfigurationAnyTabInProgress
          && !this.props.isSendCountryConfigurationAnyTabInProgress) {
      this.props.onFetchCountryConfiguration(this.props.countryCode);
      this.handleHasUnsavedChangesChanged(false);
    }
    if (prevProps.isEnableCountryProgress && !this.props.isEnableCountryProgress && !this.props.enableCountryErrors) {

      this.props.onFetchCountry(this.props.countryCode);
    }
    if (prevProps.isFetchInProgress && !this.props.isFetchInProgress) {
      this.onInit();
    }
  }


  onInit() {
    this.setState({ isLoaded: true });
  }


  onTabClick(activeViewKey) {
    this.setState({ activeViewKey });
  }


  onBackButtonClick() {
    history.push(this.context.getUrl('countries'));
  }


  onEnableCountry() {
    this.props.onOpenEnableCountryModal();
  }


  get views() {
    return ({
      General,
      UnitsOfMeasurements,
      GlucoseRanges,
      Payers,
      Identifiers,
      Meters,
    });
  }


  get viewKeysOrder() {
    return (['General', 'UnitsOfMeasurements', 'GlucoseRanges', 'Payers', 'Identifiers', 'Meters']);
  }


  get tabsLabels() {
    return this.viewKeysOrder.map((id) => ({ id, message: messages.tabs.configureCountry[camelCase(id)] }))
      .reduce((prev, current) => ({ ...prev, [current.id]: current.message }), {});
  }


  handleHasUnsavedChangesChanged = (hasUnsavedChanges) => {
    const { isNavigationBlocked } = this.props;
    if (hasUnsavedChanges) {
      if (!isNavigationBlocked) this.props.onBlockNavigation();
    } else if (isNavigationBlocked) this.props.onUnblockNavigation();
  };


  renderSwitchCountryIsActiveButton() {
    const { isActive } = this.props.country;
    const { isSDNAdmin } = this.props;
    if (isActive) {
      return (
        <Chip className="ml-6" labelMessage={messages.labels.countryEnabled} styleModifier="success" />
      );
    }
    return (
      <>
        <Chip className="ml-6" labelMessage={messages.labels.countryDisabled} styleModifier="error" />
        {
          isSDNAdmin
            ? (
              <Button
                type="button"
                styleModifier="primary"
                labelMessage={messages.buttons.enableCountry}
                className={cn('btn mr-0 ml-auto', { 'btn--filled': !isActive })}
                isInProgress={this.props.isEnableCountryProgress}
                isDisabled={this.props.isNavigationBlocked}
                titleMessage={
                  this.props.isNavigationBlocked
                    ? App.messages.infos.unsavedConfiguration
                    : null
                }
                onClick={() => this.onEnableCountry()}
              />
            )
            : null
        }
      </>

    );
  }


  renderHeader() {
    return (
      <div className={styles.configureCountry__header}>
        <Button
          type="button"
          onClick={() => this.onBackButtonClick()}
        >
          <ChevronRight className="backArrow" />
          <FormattedMessage
            {...messages.buttons.backToListCountries}
          />
        </Button>
        <div>
          <header className="text--h1"> { this.props.country.englishName }</header>
          { this.renderSwitchCountryIsActiveButton() }
        </div>
      </div>
    );
  }


  renderTabs() {
    return (
      <Tabs
        activeItem={this.state.activeViewKey}
        items={this.viewKeysOrder}
        messages={this.tabsLabels}
        action={(activeViewKey) => this.onTabClick(activeViewKey)}
      />
    );
  }


  renderContent() {
    const ActiveView = this.views[this.state.activeViewKey];
    return (
      <div className="card card--mainContent justify-content-between">
        { this.renderTabs() }
        <ActiveView
          countryCode={this.props.country.code}
          ref={this.myRef}
          onHasUnsavedChangesChanged={this.handleHasUnsavedChangesChanged}
          isFetchInProgress={this.props.isFetchInProgress}
        />
      </div>
    );
  }


  renderModals() {
    if (this.props.openModalId === constants.ENABLE_COUNTRY_MODAL) {
      return <EnableCountryModal country={this.props.country} />;
    }
    return null;
  }


  render() {
    if (!this.state.isLoaded || !this.props.country) {
      return null;
    }
    return (
      <div className={styles.configureCountry}>
        { this.renderHeader() }
        { this.renderContent() }
        { this.renderModals() }
      </div>
    );
  }

}


const mapStateToProps = (state) => (
  {
    country          : selectors.configuredCountry(state),
    isFetchInProgress: selectors.isFetchCountryInProgress(state)
                        || selectors.isFetchCountryConfigurationInProgress(state)
                        || selectors.isFetchLanguagesInProgress(state),
    isSDNAdmin                                : Account.selectors.scope(state) === SCOPE_NAMES.SDN_ADMIN,
    isSendCountryConfigurationAnyTabInProgress: selectors.isSendCountryConfigurationAnyTabInProgress(state),
    isEnableCountryProgress                   : selectors.isEnableCountryProgress(state),
    enableCountryErrors                       : selectors.enableCountryErrors(state),
    openModalId                               : App.selectors.modal(state),
    isNavigationBlocked                       : App.selectors.isNavigationBlocked(state),
  });

const mapDispatchToProps = (dispatch) => ({
  onFetchCountry             : (countryCode) => dispatch(actions.fetchCountry(countryCode)),
  onFetchCountryConfiguration: (countryCode) => dispatch(actions.fetchCountryConfiguration(countryCode)),
  onFetchLanguages           : (countryCode) => dispatch(actions.fetchLanguages(countryCode)),
  onFetchDevices             : (countryCode) => dispatch(App.actions.fetchDevices(countryCode)),
  onBlockNavigation          : () => dispatch(App.actions.blockNavigation()),
  onUnblockNavigation        : () => dispatch(App.actions.unblockNavigation()),
  onOpenUnsavedChangesModal  : () => dispatch(App.actions.openModal(App.constants.UNSAVED_CHANGES_MODAL)),
  onOpenEnableCountryModal   : () => dispatch(App.actions.openModal(constants.ENABLE_COUNTRY_MODAL)),
  onCloseModal               : () => dispatch(App.actions.closeModal()),
});


const ConnectedConfigureCountry = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfigureCountry);


export default withStyles(styles)(ConnectedConfigureCountry);
