import React, { FC } from 'react';
import { MessageDescriptor } from 'react-intl';
import Button from 'components/Form/Button';
import App from 'modules/App';


interface Props {
  to: string,
  labelMessage?: MessageDescriptor,
}


const LinkButton: FC<Props> = ({ to, labelMessage }) => (
  <Button
    type="link"
    to={to}
    labelMessage={labelMessage || App.messages.buttons.config}
    className="btn--outline"
  />
);

export default LinkButton;
