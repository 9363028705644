import toPlainObject from 'lodash/toPlainObject';
import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';
import * as actionTypes from './actionTypes';


// Sets client re-renders app after cold start
export const setClientIsInitialized = () => ({
  type: actionTypes.SET_CLIENT_IS_INITIALIZED,
});


// Set original region context
export const setOriginalRegion = (originalRegionName) => ({
  type   : actionTypes.SET_ORIGINAL_REGION,
  payload: {
    originalRegionName,
  },
});


// Change locale
export const setLocale = (locale) => ({
  type   : actionTypes.SET_LOCALE,
  payload: {
    locale,
  },
});


// Current route data
export const setRoute = (route) => ({
  type   : actionTypes.SET_ROUTE,
  payload: route,
});


// Set translated messages for the language provider
export const setTranslations = (translations = {}) => ({
  type   : actionTypes.SET_TRANSLATIONS,
  payload: {
    translations,
  },
});


/**
 * Set alert for user
 * @param {string="error"} type - type of an alert
 * @param {bool} isGlobal - indicates proper alert bus
 * @param {Object} message - intl message object
 * @param {Object} messageValues - intl message values
 */
export const setAlert = ({
  type,
  isGlobal = false,
  message,
  messageValues,
  actions = [],
  attributes = {},
}) => ({
  type   : actionTypes.SET_ALERT,
  payload: {
    alert: {
      type,
      isGlobal,
      message,
      messageValues,
      actions,
      attributes,
    },
  },
});


export const displayAlerts = () => ({
  type: actionTypes.DISPLAY_ALERTS,
});


export const dismissAlert = (alert) => ({
  type   : actionTypes.DISMISS_ALERT,
  payload: {
    alert,
  },
});


export const dismissSystemAlert = (alert) => ({
  type   : actionTypes.DISMISS_SYSTEM_ALERT,
  payload: {
    alert,
  },
});

export const dismissAllAlerts = () => ({
  type: actionTypes.DISMISS_ALL_ALERTS,
});

export const dismissAllAlertsSuccess = () => ({
  type: actionTypes.DISMISS_ALL_ALERTS_SUCCESS,
});

// Forms ---------------------------------------------------------------------------------------------------------------

export const clearForm = (formName) => ({
  type   : actionTypes.CLEAR_FORM,
  payload: {
    formName,
  },
});

export const clearFormError = (error) => ({
  type   : actionTypes.CLEAR_FORM_ERROR,
  payload: error,
});

export const commitForm = (formName) => ({
  type   : actionTypes.COMMIT_FORM,
  payload: {
    formName,
  },
});

export const resetForm = (formName) => ({
  type   : actionTypes.RESET_FORM,
  payload: {
    formName,
  },
});

export const setFormValue = (formName, input) => ({
  type   : actionTypes.SET_FORM_INPUT_VALUE,
  payload: {
    formName,
    input,
  },
});

export const setFormValues = (formName, values) => ({
  type   : actionTypes.SET_FORM_VALUES,
  payload: {
    formName,
    values,
  },
});

export const unsetFormValues = (formName, ids) => ({
  type   : actionTypes.UNSET_FORM_VALUES,
  payload: {
    formName,
    ids,
  },
});

export const setFormErrors = (formName, errors) => ({
  type   : actionTypes.SET_FORM_ERRORS,
  payload: {
    formName,
    errors,
  },
});

export const setFormBEErrors = (formName, errors) => ({
  type   : actionTypes.SET_FORM_BE_ERRORS,
  payload: {
    formName,
    errors,
  },
});

export const setFormContext = (formName, contextData) => ({
  type   : actionTypes.SET_FORM_CONTEXT_DATA,
  payload: {
    formName,
    contextData,
  },
});

export const startFormProcessing = (formName) => ({
  type   : actionTypes.START_FORM_PROCESSING,
  payload: {
    formName,
  },
});

// Navigation ----------------------------------------------------------------------------------------------------------

export const blockNavigation = () => ({
  type: actionTypes.BLOCK_NAVIGATION,
});

export const unblockNavigation = () => ({
  type: actionTypes.UNBLOCK_NAVIGATION,
});

// Dropdown ------------------------------------------------------------------------------------------------------------

export const openDropdown = (dropdownId) => ({
  type   : actionTypes.OPEN_DROPDOWN,
  payload: {
    dropdownId,
  },
});

export const closeDropdown = () => ({
  type: actionTypes.CLOSE_DROPDOWN,
});


// Modals --------------------------------------------------------------------------------------------------------------

export const openModal = (modalId) => ({
  type   : actionTypes.OPEN_MODAL,
  payload: {
    modalId,
  },
});

export const closeModal = () => ({
  type: actionTypes.CLOSE_MODAL,
});

// Floating Modals -----------------------------------------------------------------------------------------------------

export const openFloatingModal = (floatingModalId, relatedTo = null, place = 'left', position = null) => ({
  type   : actionTypes.OPEN_FLOATING_MODAL,
  payload: {
    floatingModalId,
    relatedTo,
    place,
    position,
  },
});

export const activateFloatingModal = (floatingModalId) => ({
  type   : actionTypes.ACTIVATE_FLOATING_MODAL,
  payload: {
    floatingModalId,
  },
});

export const closeFloatingModal = (floatingModalId) => ({
  type   : actionTypes.CLOSE_FLOATING_MODAL,
  payload: {
    floatingModalId,
  },
});

// Dictionaries --------------------------------------------------------------------------------------------------------

export const fetchDevices = () => ({
  type: actionTypes.FETCH_DEVICES,
});

export const fetchDevicesSuccess = (devices) => ({
  type   : actionTypes.FETCH_DEVICES_SUCCESS,
  payload: {
    devices,
  },
});

export const fetchDevicesError = (error) => ({
  type: actionTypes.FETCH_DEVICES_ERROR,
  error,
});


export const fetchLanguages = () => ({
  type             : actionTypes.FETCH_LANGUAGES,
  [WAIT_FOR_ACTION]: actionTypes.FETCH_LANGUAGES_SUCCESS,
  [ERROR_ACTION]   : actionTypes.FETCH_LANGUAGES_ERROR,
});

export const fetchLanguagesSuccess = (languages) => ({
  type   : actionTypes.FETCH_LANGUAGES_SUCCESS,
  payload: {
    languages,
  },
});

export const fetchLanguagesError = (error) => ({
  type: actionTypes.FETCH_LANGUAGES_ERROR,
  error,
});


export const fetchLocalizationResources = () => ({
  type             : actionTypes.FETCH_LOCALIZATION_RESOURCES,
  [WAIT_FOR_ACTION]: actionTypes.FETCH_LOCALIZATION_RESOURCES_SUCCESS,
  [ERROR_ACTION]   : actionTypes.FETCH_LOCALIZATION_RESOURCES_ERROR,
});

export const fetchLocalizationResourcesSuccess = (localizationResources) => ({
  type   : actionTypes.FETCH_LOCALIZATION_RESOURCES_SUCCESS,
  payload: {
    localizationResources,
  },
});

export const fetchLocalizationResourcesError = (error) => ({
  type: actionTypes.FETCH_LOCALIZATION_RESOURCES_ERROR,
  error,
});


//----------------------------------------------------------------------------------------------------------------------

export const setPrintMode = (printMode) => ({
  type   : actionTypes.SET_PRINT_MODE,
  payload: {
    printMode,
  },
});


// Download ------------------------------------------------------------------------------------------------------------

export const download = ({ id, url }) => ({
  type   : actionTypes.DOWNLOAD,
  payload: {
    id,
    url,
  },
});

export const downloadSuccess = (id) => ({
  type   : actionTypes.DOWNLOAD_SUCCESS,
  payload: {
    id,
  },
});

export const downloadError = (id, error) => ({
  type   : actionTypes.DOWNLOAD_ERROR,
  payload: {
    id,
  },
  error,
});

// Upload --------------------------------------------------------------------------------------------------------------

export const upload = (file, type) => ({
  type   : actionTypes.UPLOAD,
  payload: {
    file,
    type,
  },
  meta: {
    file: toPlainObject(file),
    type,
  },
});

export const uploadProgress = (progress, meta) => ({
  type   : actionTypes.UPLOAD_PROGRESS,
  payload: progress,
  meta,
});

export const uploadSuccess = (response, meta) => ({
  type   : actionTypes.UPLOAD_SUCCESS,
  payload: response,
  meta,
});

export const uploadError = (error, meta) => ({
  type: actionTypes.UPLOAD_ERROR,
  error,
  meta,
});

export const removeUpload = (id) => ({
  type   : actionTypes.REMOVE_UPLOAD,
  payload: {
    id,
  },
});

export const clearUploads = () => ({
  type: actionTypes.CLEAR_UPLOADS,
});

// SignalR -------------------------------------------------------------------------------------------------------------

export const createSignalRHub = () => ({
  type: actionTypes.SIGNALR_CREATE_HUB,
});

export const setSignalRConnected = () => ({
  type: actionTypes.SIGNALR_SET_CONNECTED,
});

export const setSignalRError = () => ({
  type: actionTypes.SIGNALR_ERROR,
});

export const setSignalRDisconnected = () => ({
  type: actionTypes.SIGNALR_SET_DISCONNECTED,
});

export const signalRReconnected = () => ({
  type: actionTypes.SIGNALR_RECONNECTED,
});

export const signalRSend = (sendMessageName, data) => ({
  type   : actionTypes.SIGNALR_SEND,
  payload: {
    sendMessageName,
    data,
  },
});

export const signalRInvoke = (invokeMessageName, data, sagaActions) => ({
  type   : actionTypes.SIGNALR_INVOKE,
  payload: {
    invokeMessageName,
    data,
    sagaActions,
  },
});

// SignalR Notifications
// actions are dispatched by BE signalR notification (check use of SIGNALR_NOTIFICATION_RECEIVE_MSG)
// actionTypes should use SIGNALR_MODULE_ID prefix for better logs readability

export const updatePublishSnapshots = () => ({
  type: actionTypes.UPDATE_PUBLISH_SNAPSHOTS,

});

export const updatePublishSnapshotsSuccess = () => ({
  type: actionTypes.UPDATE_PUBLISH_SNAPSHOTS_SUCCESS,
});

export const updatePublishProgress = ({ status, lastPublish }) => ({
  type   : actionTypes.UPDATE_PUBLISH_PROGRESS,
  payload: {
    status,
    lastPublish,
  },
});


// Websockets ----------------------------------------------------------------------------------------------------------

export const websocketStart = () => ({
  type: actionTypes.WEBSOCKET_START,
});

export const websocketStop = () => ({
  type: actionTypes.WEBSOCKET_STOP,
});

export const websocketSend = (msg) => ({
  type   : actionTypes.WEBSOCKET_SEND,
  payload: msg,
});

export const websocketStoreState = (state) => ({
  type   : actionTypes.WEBSOCKET_STORE_STATE,
  payload: {
    state,
  },
});

// GTM -----------------------------------------------------------------------------------------------------------------

export const loadGTM = () => ({
  type: actionTypes.LOAD_GTM,
});

export const loadGTMSuccess = () => ({
  type: actionTypes.LOAD_GTM_SUCCESS,
});

export const loadGTMFailed = (error) => ({
  type   : actionTypes.LOAD_GTM_FAILED,
  payload: error,
});

// Hotjar --------------------------------------------------------------------------------------------------------------

export const loadHotjar = () => ({
  type: actionTypes.LOAD_HOTJAR,
});

export const loadHotjarSuccess = () => ({
  type: actionTypes.LOAD_HOTJAR_SUCCESS,
});

export const loadHotjarFailed = (error) => ({
  type: actionTypes.LOAD_HOTJAR_FAILED,
  error,
});

// Feature Toggle ------------------------------------------------------------------------------------------------------

export const setFeatureToggle = ({ name, value }) => ({
  type   : actionTypes.SET_FEATURE_TOGGLE,
  payload: { name, value },
});

// Tracking ------------------------------------------------------------------------------------------------------------

export const pushVirtualPageview = ({ pathname, search, hash } = {}) => ({
  type   : actionTypes.PUSH_VIRTUAL_PAGEVIEW,
  payload: {
    pathname,
    search,
    hash,
  },
});

// System alerts -------------------------------------------------------------------------------------------------------

export const fetchSystemAlerts = () => ({
  type: actionTypes.FETCH_SYSTEM_ALERTS,
});

export const fetchSystemAlertsSuccess = (systemAlerts) => ({
  type   : actionTypes.FETCH_SYSTEM_ALERTS_SUCCESS,
  payload: {
    systemAlerts,
  },
});

export const fetchSystemAlertsError = (error) => ({
  type: actionTypes.FETCH_SYSTEM_ALERTS_ERROR,
  error,
});

export const fetchSystemAlertsSettings = () => ({
  type: actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS,
});

export const fetchSystemAlertsSettingsSuccess = (systemAlertsSettings) => ({
  type   : actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS_SUCCESS,
  payload: {
    systemAlertsSettings,
  },
});

export const fetchSystemAlertsSettingsError = (error) => ({
  type: actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS_ERROR,
  error,
});
