import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import * as hooks from './hooks';
import reducer from './reducer';
import sagas, { dispatchError } from './sagas';
import * as selectors from './selectors';
import * as shapes from './shapes';
import messages from './messages';

import AlertsBus from './components/AlertsBus';
import IntlHelmet from './components/IntlHelmet';
import LanguageProvider from './components/LanguageProvider';
import Error from './components/Errors/Error';


const components = {
  AlertsBus,
  IntlHelmet,
  LanguageProvider,
  Error,
};


export default {
  actions,
  actionTypes,
  components,
  constants,
  hooks,
  reducer,
  sagas,
  dispatchError,
  selectors,
  shapes,
  messages,
};
