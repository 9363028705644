import React from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import App from 'modules/App';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import CollapsibleSection from 'components/CollapsibleSection';
import messages from '../../../../../messages';
import styles from '../GlucoseRanges.pcss';


class GlucoseLevel extends React.PureComponent {

  static propTypes = {
    // Explicit props
    formValues: PropTypes.object,
    type      : PropTypes.string,
    unitSymbol: PropTypes.string,
    // Explicit actions
    onSetValue: PropTypes.func,
  };

  onSetValue(input) {
    const { value } = input;
    let { id } = input;
    id = id.replace('-toDisplay', '');
    const roundedValue = isNumber(value)
      ? toNumber(value.toFixed(1))
      : value;
    this.props.onSetValue({ id, value });
    this.props.onSetValue({ id: `${id}-toDisplay`, value: roundedValue });
  }

  shouldSetDefaultValueAsPlaceholder(type, threshold) {
    if (type === 'default') return false;
    return get(this.props.formValues, this.getRelatedDefaultValueId(threshold), false);
  }

  getRelatedDefaultValueId(threshold) {
    return `values.default.${threshold}`;
  }


  getValue(id) {
    return get(this.props.formValues, `values.${id}-toDisplay`, null);
  }

  renderThreshold(threshold, label) {
    const { type, formValues } = this.props;
    const unitContentCss = this.props.unitSymbol === 'mg/dL' ? 'mgdl' : 'mmolL';
    const placeholder = this.shouldSetDefaultValueAsPlaceholder(type, threshold)
      ? get(formValues, this.getRelatedDefaultValueId(threshold)).toLocaleString()
      : App.messages.placeholders.typeValue;
    return (
      <div className="row align-items-center">
        <div className="col">
          <FormGroup
            id={`${type}.${threshold}`}
            labelMessage={label}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={placeholder}
              onChange={(input) => this.onSetValue(input)}
              type="number"
              classNameWrapper={cn(styles.glucoseLevel__unitSymbol,
                {
                  [styles[`glucoseLevel__unitSymbol--${unitContentCss}`]]: unitContentCss,
                },)}
            />
          </FormGroup>
        </div>
      </div>
    );
  }


  render() {
    const { type } = this.props;
    const { labels } = messages;
    return (
      <CollapsibleSection headerMessage={messages.headers.glucoseType[type]}>
        <div className="row mb-6">
          <div className="col-4">
            {this.renderThreshold('preMeal.lowThreshold-toDisplay', labels.glucosePreMealLow)}
          </div>
          <div className="col-4">
            {this.renderThreshold('preMeal.highThreshold-toDisplay', labels.glucosePreMealHigh)}
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-4">
            {this.renderThreshold('postMeal.lowThreshold-toDisplay', labels.glucosePostMealLow)}
          </div>
          <div className="col-4">
            {this.renderThreshold('postMeal.highThreshold-toDisplay', labels.glucosePostMealHigh)}
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-4">
            {this.renderThreshold('critical.lowThreshold-toDisplay', labels.glucoseCriticalLow)}
          </div>
          <div className="col-4">
            {this.renderThreshold('critical.highThreshold-toDisplay', labels.glucoseCriticalHigh)}
          </div>
        </div>
      </CollapsibleSection>
    );
  }

}

export default withStyles(styles)(GlucoseLevel);
