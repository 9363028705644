import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Form from 'components/Form';
import Select from 'components/Form/Select';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import SwitchButton from 'components/Form/SwitchButton';
import StandardActions from 'components/Form/StadardActions';
import FormContainerAbstract from 'components/FormContainerAbstract';
import App from 'modules/App';
import Account from 'modules/Account';
import { WEEK_DAYS } from 'modules/App/constants';
import { SCOPE_NAMES } from 'modules/Account/constants';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';
import messages from '../../../../messages';
import * as constants from '../../../../constants';
import validatorRules from './validatorRules.json';
import MFADisableWarningModal from './MFADisableWarningModal';
import styles from './General.pcss';


class GeneralForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    countryCode               : PropTypes.string.isRequired,
    isFetchInProgress         : PropTypes.bool.isRequired,
    isConfiguredCountryActive : PropTypes.bool.isRequired,
    isSDNAdmin                : PropTypes.bool,
    // Explicit actions
    onHasUnsavedChangesChanged: PropTypes.func,
    // Implicit props
    // Implicit actions
    onClose                   : PropTypes.func,
  };


  constructor(props) {
    super(props);

    this.validatorRules = { ...validatorRules };
    if (this.props.isConfiguredCountryActive) {
      this.validatorRules.termsOfServiceURL = `required, ${this.validatorRules.termsOfServiceURL}`;
      this.validatorRules.firstDayOfWeek = 'required';
    }
    this.state = {
      formInitialState  : JSON.parse(JSON.stringify(this.props.generalSettings)),
      hasUnsavedChanges : false,
      shouldReset       : false,
      isCallingCodeFocus: false,
    };

    this.props.onSetFormValues(this.props.generalSettings);
  }


  componentDidUpdate(prevProps) {
    if (prevProps.isUpdateInProgress && !this.props.isUpdateInProgress) {
      this.onSetShouldReset();
    }
    if (this.state.shouldReset && !this.props.isFetchInProgress) {
      this.onInitialize();
    }
    this.setFormStatus();
  }


  onSetShouldReset() {
    this.setState({ shouldReset: true });
  }


  onInitialize() {
    this.setState({
      formInitialState : JSON.parse(JSON.stringify(this.props.generalSettings)),
      hasUnsavedChanges: false,
      shouldReset      : false,
    });
    this.props.onSetFormValues(this.props.generalSettings);
  }


  onReset() {
    this.props.onSetFormValues(JSON.parse(JSON.stringify(this.state.formInitialState)));
    this.props.onFormErrors(null);
  }


  onMFASMSToggle(input) {
    const { id, value } = input;
    const initValue = get(this.state.formInitialState, id, false);
    if (value && !initValue) {
      this.setState({ mfaConfirmInput: input,
        mfaConfirmMode : 'enableMFASMS' });
      this.props.onOpenModal(constants.MFA_WARNING_MODAL);
      return;
    }
    if (!value && initValue && this.props.isConfiguredCountryActive) {
      this.setState({
        mfaConfirmInput: input,
        mfaConfirmMode : 'disableMFASMS',
      });
      this.props.onOpenModal(constants.MFA_WARNING_MODAL);
      return;
    }
    this.onSetValue(input);
  }

  onMFAEmailToggle(input) {
    const { id, value } = input;
    const initValue = get(this.state.formInitialState, id, false);
    if (!value && initValue && this.props.isConfiguredCountryActive) {
      this.setState({
        mfaConfirmInput: input,
        mfaConfirmMode : 'disableMFAEmail',
      });
      this.props.onOpenModal(constants.MFA_WARNING_MODAL);
      return;
    }
    this.onSetValue(input);
  }

  onConfirmMFA() {
    this.props.onSetFormValue(this.state.mfaConfirmInput);
    this.props.onCloseModal(constants.MFA_WARNING_MODAL);
  }


  onSetCallingCode({ id, value }) {
    this.props.onSetFormValue({ id, value });
  }


  onValidate(rules) {
    const isMFASMSEnabled = get(this.props.formValues, 'values.isMFASMSEnabled', false);
    if (isMFASMSEnabled) {
      return super.onValidate({
        ...rules,
        callingCode: `${rules.callingCode}, required`,
      });
    }
    return super.onValidate(rules);
  }


  setFormStatus() {
    const hasUnsavedChanges = !isEqual(this.state.formInitialState, this.props.formValues.values);
    if (this.state.formInitialState && this.state.hasUnsavedChanges !== hasUnsavedChanges) {
      this.setState({ hasUnsavedChanges });
      this.props.onHasUnsavedChangesChanged(hasUnsavedChanges);
    }
  }


  get isInitialized() {
    return this.state.formInitialState && this.props.languageOptions.length;
  }


  get weekDaysOptions() {
    return keys(WEEK_DAYS).map((day) => (
      {
        firstDayOfWeek: day,
        labelMessage  : this.props.intl.formatMessage(App.messages.weakDays[day]),
      }
    ));
  }


  get languageOptions() {
    return (this.props.languageOptions || []).sort((a, b) => {
      if (a.englishName > b.englishName) { return 1; }
      return -1;
    });
  }


  renderGeneralOptionsSwitchSection() {
    return (
      <div className="row no-gutters">
        <FormGroup
          id="isLicenceMandatory"
          className="col-auto mr-10"
          formValues={this.props.formValues}
        >
          <SwitchButton
            inputValue="true"
            labelMessage={messages.headers.isLicenceMandatory}
            onChange={this.props.onSetFormValue}
          />
        </FormGroup>
        <FormGroup
          id="isPrivateCloud"
          className="col-auto"
          formValues={this.props.formValues}
        >
          <SwitchButton
            inputValue="true"
            labelMessage={messages.headers.isPrivateCloud}
            onChange={this.props.onSetFormValue}
          />
        </FormGroup>
      </div>
    );
  }


  renderDefaultLanguage() {
    return (
      <FormGroup
        id="defaultLanguageCode"
        className="col-3 pl-0"
        labelMessage={messages.labels.defaultCountryLanguage}
        formValues={this.props.formValues}
      >
        <Select
          optionsFrom={this.languageOptions}
          valueKey="code"
          labelKey="englishName"
          noValueMessage={messages.placeholders.language}
          onChange={(input) => this.onSetValue(input)}
        />
      </FormGroup>
    );
  }


  renderFirstDayOfWeek() {
    return (
      <FormGroup
        id="firstDayOfWeek"
        className="col-3 pl-0"
        labelMessage={messages.labels.firstDayOfWeek}
        formValues={this.props.formValues}
      >
        <Select
          optionsFrom={this.weekDaysOptions}
          valueKey="firstDayOfWeek"
          labelKey="labelMessage"
          noValueMessage={messages.placeholders.firstDayOfWeek}
          onChange={(input) => this.onSetValue(input)}
        />
      </FormGroup>
    );
  }


  renderTermsOfServices() {
    return (
      <FormGroup
        id="termsOfServiceURL"
        className="col-3 pl-0"
        labelMessage={messages.labels.termsOfServices}
        formValues={this.props.formValues}
      >
        <Input
          placeholder={messages.placeholders.termsOfServices}
          onChange={(input) => this.onSetValue(input)}
        />
      </FormGroup>
    );
  }


  renderGeneralOptions() {
    return (
      <>
        <h5 className="text--h5">
          <FormattedMessage {...App.messages.headers.generalOptions} />
        </h5>
        { this.renderGeneralOptionsSwitchSection() }
        { this.renderDefaultLanguage() }
        { this.renderFirstDayOfWeek() }
        { this.renderTermsOfServices() }
      </>
    );
  }


  renderMFA() {
    const hasCallingCode = get(this.props.formValues, 'values.callingCode') || this.state.isCallingCodeFocus;
    const { isSDNAdmin } = this.props;
    return (
      <>
        <h5 className="text--h5">
          <FormattedMessage {...messages.headers.MFA} />
        </h5>
        <div className="row no-gutters">
          <FormGroup
            id="isMFASMSEnabled"
            className="col-auto mr-10"
            formValues={this.props.formValues}
          >
            <SwitchButton
              inputValue="true"
              labelMessage={messages.labels.sms}
              isDisabled={!isSDNAdmin}
              onChange={
                (input) => {
                  this.onMFASMSToggle(input);
                }
              }
            />
          </FormGroup>
          <FormGroup
            id="isMFAEmailEnabled"
            className="col-auto"
            formValues={this.props.formValues}
          >
            <SwitchButton
              inputValue="true"
              labelMessage={messages.labels.email}
              isDisabled={!isSDNAdmin}
              onChange={
                (input) => {
                  this.onMFAEmailToggle(input);
                }
              }
            />
          </FormGroup>
        </div>
        <FormGroup
          id="callingCode"
          className={
            cn({
              [styles.callingCode]: hasCallingCode,
            },
            'col-3 pl-0')
          }
          labelMessage={messages.labels.callingCode}
          formValues={this.props.formValues}
        >
          <Input
            type="number"
            placeholder={!this.state.isCallingCodeFocus ? messages.placeholders.callingCode : null}
            onChange={(input) => this.onSetValue(input)}
            onFocus={
              () => this.setState({
                isCallingCodeFocus: true,
              })
            }
            onBlur={
              () => this.setState({
                isCallingCodeFocus: false,
              })
            }
          />
        </FormGroup>
      </>
    );
  }


  renderCDAStoreLinks() {
    return (
      <div>
        <h5 className="text--h5">
          <FormattedMessage {...messages.headers.cdaStoreLink} />
        </h5>
        <div className="row no-gutters">
          <FormGroup
            id="iosStoreURL"
            className="col-3 pr-4 mr-11"
            labelMessage={messages.labels.linkForIOS}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.linkForIOS}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
          <FormGroup
            id="androidStoreURL"
            className="col-3 pr-4"
            labelMessage={messages.labels.linkForAndroid}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.linkForAndroid}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
      </div>
    );
  }


  renderAvailableScope(id) {
    return (
      <FormGroup
        key={`scopes.${id}`}
        id={`scopes.${id}`}
        className="col-2 pl-0"
        formValues={this.props.formValues}
      >
        <SwitchButton
          inputValue="true"
          labelMessage={messages.labels[id]}
          onChange={this.props.onSetFormValue}
        />
      </FormGroup>
    );
  }


  renderAvailableScopes() {
    return (
      <div>
        <h5 className="text--h5">
          <FormattedMessage {...messages.headers.availableScopes} />
        </h5>
        { Object.values(Account.constants.ACCOUNT_SCOPE_NAMES).map((id) => this.renderAvailableScope(camelCase(id))) }
      </div>
    );
  }

  renderModals() {
    if (this.props.openModalId === constants.MFA_WARNING_MODAL) {
      return (
        <MFADisableWarningModal
          openModalId={this.props.openModalId}
          mfaConfirmMode={this.state.mfaConfirmMode}
          onConfirm={() => this.onConfirmMFA()}
          onClose={this.props.onCloseModal}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <>
        <Form
          onSubmit={() => this.onSubmit()}
          onReset={() => this.onReset()}
        >
          { this.renderGeneralOptions() }
          { this.renderMFA() }
          { this.renderCDAStoreLinks() }
          { this.renderAvailableScopes() }
          <StandardActions
            hasChanges={this.state.hasUnsavedChanges}
            isInProgress={this.props.isUpdateInProgress || this.props.isFetchInProgress}
            infoMessage={this.props.isConfiguredCountryActive ? messages.infos.saveOnActiveCountry : null}
          />
        </Form>
        { this.renderModals() }
      </>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues: App.selectors.formSelector(
    constants.CONFIGURE_COUNTRY_GENERAL_TAB_FORM,
  )(state),
  generalSettings          : selectors.generalSettings(state),
  languageOptions          : selectors.languages(state),
  isConfiguredCountryActive: selectors.isConfiguredCountryActive(state),
  isUpdateInProgress       : selectors.isUpdateCountryConfigurationGeneralInProgress(state),
  isSDNAdmin               : Account.selectors.scope(state) === SCOPE_NAMES.SDN_ADMIN,
  openModalId              : App.selectors.modal(state),

});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.CONFIGURE_COUNTRY_GENERAL_TAB_FORM;
  return {
    onSubmit        : (values) => dispatch(actions.updateCountryConfigurationGeneral(values)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
    onOpenModal     : (modalId) => dispatch(App.actions.openModal(modalId)),
    onCloseModal    : (modalId) => dispatch(App.actions.closeModal(modalId)),
  };
};


const ConnectedGeneralForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralForm);


export default withStyles(styles)(injectIntl(ConnectedGeneralForm));
