import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import isObject from 'lodash/isObject';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import times from 'lodash/times';
import isFunction from 'lodash/isFunction';
import Pagination from 'components/Pagination';
import * as shapes from './shapes';


class Table extends React.Component {

  static propTypes = {
    // Explicit props
    idKey                      : PropTypes.string.isRequired,
    className                  : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    schema                     : shapes.schema.isRequired,
    entities                   : PropTypes.array.isRequired,
    page                       : PropTypes.number,
    perPage                    : PropTypes.number,
    totalEntities              : PropTypes.number,
    isInProgress               : PropTypes.bool,
    isPerPageOff               : PropTypes.bool,
    isHeaderOff                : PropTypes.bool,
    isOnPageChangeReturnOneSide: PropTypes.bool,

    // Explicit actions
    onPageChange   : PropTypes.func,
    onPerPageChange: PropTypes.func,
  };


  static defaultProps = {
    page                       : 0,
    perPage                    : 10,
    isHeaderOff                : false,
    isOnPageChangeReturnOneSide: false,
  };


  constructor(props) {
    super(props);
    const { page, perPage } = props;
    this.state = {
      page,
      perPage,
    };
  }


  componentDidMount() {
    this.onPageChange(this.props.page);
  }


  componentDidUpdate(prevProps) {
    if (
      prevProps.page !== this.props.page
      || prevProps.totalEntities !== this.props.totalEntities
      || prevProps.entities.length !== this.props.entities.length
    ) {
      this.onPageChange(this.props.page);
    }
  }

  async onPageChange(page) {
    const { perPage } = this.state;
    const { totalPages } = this;

    if (isNull(totalPages)) {
      return;
    }

    if (page >= totalPages || totalPages === 1) {
      page = 0;
    }

    if (page === this.state.page) {
      return;
    }

    if (this.props.onPageChange) {
      await this.props.onPageChange({ page, perPage });
    }

    this.setState({ page });
  }


  async onPerPageChange(perPage) {
    const { page } = this.state;
    if (this.props.onPerPageChange) await this.props.onPerPageChange({ page, perPage });
    this.setState({ perPage });
  }


  get pagedEntities() {
    const { entities, idKey, isInProgress, onPageChange, isOnPageChangeReturnOneSide } = this.props;
    const { page, perPage } = this.state;
    if (perPage === -1) return entities;
    if (isInProgress) {
      return times(perPage, (n) => ({ [idKey]: n }));
    }
    if (onPageChange && isOnPageChangeReturnOneSide) {
      return entities;
    }
    return entities.slice(perPage * page, perPage * (page + 1));
  }


  get totalPages() {
    const { perPage, entities, totalEntities } = this.props;
    const entitiesCount = isUndefined(totalEntities) ? entities.length : totalEntities;
    if (isNull(entitiesCount)) {
      return null;
    }
    return Math.ceil(entitiesCount / perPage);
  }


  renderHeaderCell(schemaItem, idx) {
    const { labelMessage = '', key, cellClassName, onClickHeader } = schemaItem;
    let message = null;
    if (isFunction(labelMessage)) {
      message = labelMessage();
    } else {
      message = isObject(labelMessage) ? <FormattedMessage {...labelMessage} /> : labelMessage;
    }
    return (
      <th key={idx} className={cn('table__cell', `table__cell--${key}`, cellClassName)} onClick={onClickHeader}>
        { message }
      </th>
    );
  }


  renderHeader() {
    if (this.props.isHeaderOff) return null;
    const { schema } = this.props;
    return (
      <thead className="table__header">
        <tr className="table__row">
          { map(schema, (schemaItem, idx) => this.renderHeaderCell(schemaItem, idx)) }
        </tr>
      </thead>
    );
  }


  renderRowCell(entity, entityKey, schemaItem) {
    const { key, cellClassName, renderer } = schemaItem;
    const value = renderer ? renderer(entity) : get(entity, key, '');
    return (
      <td
        key={`${entityKey}-${key}`}
        className={cn('table__cell', `table__cell--${key}`, cellClassName)}
      >{ value }
      </td>
    );
  }


  renderRow(entity) {
    const { schema, idKey, isInProgress } = this.props;
    const entityKey = get(entity, idKey);
    return (
      <tr key={entityKey} className={cn('table__row', { fadingLoader: isInProgress })}>
        { map(schema, (schemaItem) => this.renderRowCell(entity, entityKey, schemaItem)) }
      </tr>
    );
  }


  renderBody() {
    return (
      <tbody>
        { map(this.pagedEntities, (entity) => this.renderRow(entity)) }
      </tbody>
    );
  }


  render() {
    return (
      <div>
        <div className="table__wrapper">
          <table className={cn('table', this.props.className)}>
            { this.renderHeader() }
            { this.renderBody() }
          </table>
        </div>
        {
          this.props.perPage !== -1 && (
            <Pagination
              perPage={this.state.perPage}
              onPerPageChange={(perPage) => this.onPerPageChange(perPage)}
              onPageChange={(page) => this.onPageChange(page)}
              page={this.state.page}
              totalPages={this.totalPages}
              isPerPageOff={this.props.isPerPageOff}
            />
          )
        }
      </div>
    );
  }

}


export default Table;
