import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import MiniSearch from 'minisearch';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import { StatusDot } from 'components/DataTable';
import Table from 'components/Table';
import SortingIcon from 'components/SortingIcon';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import DeleteIcon from 'svg/delete.svg';
import Loupe from 'svg/loupe-light.svg';
import AddIcon from 'svg/add.svg';
import App from 'modules/App';
import Account from 'modules/Account';
import { SCOPE_NAMES } from 'modules/Account/constants';
import { AppContext } from 'context';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as shapes from '../../shapes';
import messages from '../../messages';
import * as selectors from '../../selectors';
import AddCountryModal from './AddCountryModal';
import DeleteCountryModal from './DeleteCountryModal';
import styles from './ListCountries.pcss';


class ListCountries extends React.PureComponent {

  static contextType = AppContext;

  static propTypes = {
    // Explicit props
    // Implicit props
    isFetchInProgress      : PropTypes.bool,
    isAddCountryInProgress : PropTypes.bool,
    isDeleteCountryProgress: PropTypes.bool,
    hasAddCountryErrors    : PropTypes.bool,
    hasDeleteCountryErrors : PropTypes.bool,
    countries              : PropTypes.arrayOf(shapes.country),
    languageOptions        : PropTypes.arrayOf(shapes.language),
    canDelete              : PropTypes.bool,
    canAdd                 : PropTypes.bool,
    openModalId            : PropTypes.string,
    // Implicit actions
    onOpenModal            : PropTypes.func,
    onCloseModal           : PropTypes.func,
    onFetchCountries       : PropTypes.func,
    onFetchLanguages       : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      page              : 0,
      search            : '',
      countryToDelete   : null,
      isInitialized     : false,
      enableFlagSorting : 'default',
      countryNameSorting: 'default',
    };
    this.miniSearch = new MiniSearch({
      idField      : 'code',
      fields       : ['englishName', 'code'],
      storeFields  : ['englishName', 'code', 'isActive'],
      searchOptions: {
        fuzzy  : 0,
        prefix : true,
        refresh: false,
      },
    });
  }


  componentDidMount() {
    this.props.onFetchCountries();
    this.props.onFetchLanguages();
  }


  componentDidUpdate(prevProps) {
    if (prevProps.isFetchInProgress && !this.props.isFetchInProgress) {
      this.miniSearch.removeAll();
      this.miniSearch.addAll(this.props.countries);
      this.onRefresh();
      this.onInitialized();
    }
    if (prevProps.isAddCountryInProgress && !this.props.isAddCountryInProgress && !this.props.hasAddCountryErrors) {
      this.props.onFetchCountries();
    }
    if (prevProps.isDeleteCountryProgress && !this.props.isDeleteCountryProgress) {
      this.props.onFetchCountries();
      this.onDeleted();
    }
  }


  onInitialized() {
    this.setState({ isInitialized: true });
  }


  onRefresh() {
    this.setState((state) => ({ refresh: !state.refresh }));
  }


  onClickDeleteCountry(country) {
    if (!country.isActive) {
      this.setState({ countryToDelete: country });
      this.props.onOpenModal(constants.DELETE_COUNTRY_MODAL);
    }
  }

  onDeleted() {
    this.setState({ countryToDelete: null });
    this.props.onCloseModal();
  }


  onEnableFlagSortingClick() {
    switch (this.state.enableFlagSorting) {
      case 'default': {
        this.setState({ enableFlagSorting: 'desc' });
        break;
      }
      case 'desc': {
        this.setState({ enableFlagSorting: 'asc' });
        break;
      }
      case 'asc': {
        this.setState({ enableFlagSorting: 'default' });
        break;
      }
      // eslint-disable-next-line no-empty
      default: {}
    }
  }


  onCountryNameSortingClick() {
    switch (this.state.countryNameSorting) {
      case 'default': {
        this.setState({ countryNameSorting: 'desc' });
        break;
      }
      case 'desc': {
        this.setState({ countryNameSorting: 'asc' });
        break;
      }
      case 'asc': {
        this.setState({ countryNameSorting: 'default' });
        break;
      }
      // eslint-disable-next-line no-empty
      default: {}
    }
    this.setState({ enableFlagSorting: 'default' });
  }


  get countries() {
    const { search, enableFlagSorting } = this.state;
    const countries = isEmpty(search) ? this.props.countries : this.miniSearch.search(search);
    return countries.sort((a, b) => {
      if (enableFlagSorting !== 'default') {
        if (a.isActive !== b.isActive) {
          if (enableFlagSorting === 'desc') return a.isActive ? -1 : 1;
          return a.isActive ? 1 : -1;
        }
      }
      return this.orderByEnglishName(a, b);
    });
  }


  get schema() {
    const schema = [
      {
        key          : 'englishName',
        labelMessage : () => this.renderCountryNameHeader(),
        onClickHeader: () => this.onCountryNameSortingClick(),
        renderer     : (country) => <b>{ country.englishName }</b>,
      },
      {
        key         : 'code',
        labelMessage: messages.labels.countryCode,
        renderer    : (country) => <p>{ country.code }</p>,
      },
      {
        key          : 'isActive',
        labelMessage : () => this.renderIsEnabledFlagHeader(),
        onClickHeader: () => this.onEnableFlagSortingClick(),
        renderer     : (country) => <div>{ this.renderIsEnabledFlag(country.isActive) }</div>,
      },
      {
        key     : 'config',
        renderer: (country) => this.renderConfigButton(country),
      },
    ];
    if (this.props.canDelete) {
      schema.push(
        {
          key     : 'delete',
          renderer: (country) => this.renderDeleteIcon(country),
        },
      );
    }

    return schema;
  }

  orderByEnglishName(a, b) {
    const { countryNameSorting } = this.state;
    if (countryNameSorting === 'desc' || countryNameSorting === 'default') {
      if (lowerCase(a.englishName) > lowerCase(b.englishName)) { return 1; }
      if (lowerCase(a.englishName) < lowerCase(b.englishName)) { return -1; }
    }
    if (countryNameSorting === 'asc') {
      if (lowerCase(a.englishName) < lowerCase(b.englishName)) { return 1; }
      if (lowerCase(a.englishName) > lowerCase(b.englishName)) { return -1; }
    }
    return 0;
  }

  renderCountryNameHeader() {
    return (
      <div className={cn(styles.countriesTable__sortingHeader, 'd-flex align-item-center')}>
        <FormattedMessage {...messages.labels.countryName} />
        <SortingIcon type={this.state.countryNameSorting} className={styles.countriesTable__sortingHeaderIcon} />
      </div>
    );
  }


  renderIsEnabledFlagHeader() {
    return (
      <div className={cn(styles.countriesTable__sortingHeader, 'd-flex align-item-center')}>
        <FormattedMessage {...messages.labels.isEnableFlag} />
        <SortingIcon type={this.state.enableFlagSorting} className={styles.countriesTable__sortingHeaderIcon} />
      </div>
    );
  }


  renderIsEnabledFlag(isActive) {
    const status = isActive ? 'success' : 'error';
    const labelMessage = isActive ? messages.labels.enable : messages.labels.disable;
    return <StatusDot {...{ status }}><FormattedMessage {...labelMessage} /></StatusDot>;
  }


  renderDeleteIcon(country) {
    return (
      <DeleteIcon
        className={
          cn(styles.countriesTable__deleteIcon,
            { [styles['countriesTable__deleteIcon--inactive']]: country.isActive })
        }
        onClick={() => this.onClickDeleteCountry(country)}
      />
    );
  }


  renderConfigButton({ code: countryCode }) {
    return (
      <Button
        labelMessage={messages.buttons.config}
        className="btn--outline"
        type="link"
        to={this.context.getUrl('country-configuration', { countryCode })}
      />
    );
  }


  renderAddCountryButton() {
    if (!this.props.canAdd) return null;
    return (
      <Button
        className="btn--primary btn--filled"
        onClick={() => this.props.onOpenModal(constants.ADD_COUNTRY_MODAL)}
      >
        <>
          <AddIcon className={styles.header__addButton__icon} />
          <p><FormattedMessage {...messages.buttons.addCountry} /></p>
        </>
      </Button>
    );
  }


  renderSearch() {
    return (
      <div className={styles.header__search}>
        <Input
          placeholder={messages.placeholders.searchCountry}
          value={this.state.search}
          onChange={(input) => this.setState({ search: input.value })}
        />
        <Loupe className={styles.search__loupe} />
      </div>
    );
  }


  renderHeader() {
    return (
      <div className={styles.header}>
        <header className="text--h1">
          <FormattedMessage {...messages.headers.countries} />
        </header>
        { this.renderSearch() }
        { this.renderAddCountryButton() }
      </div>
    );
  }


  renderTable() {
    return (
      <Table
        idKey="code"
        className={styles.countriesTable}
        schema={this.schema}
        entities={this.countries}
        page={this.state.page}
        perPage={10}
        isPerPageOff
      />
    );
  }


  renderModals() {
    if (this.props.openModalId === constants.ADD_COUNTRY_MODAL) {
      if (!this.props.canAdd) return null;
      return (
        <AddCountryModal
          isInProgress={this.props.isAddCountryInProgress}
          openModalId={this.props.openModalId}
          languageOptions={this.props.languageOptions}
          hasErrors={this.props.hasAddCountryErrors}
        />
      );
    }
    if (this.props.openModalId === constants.DELETE_COUNTRY_MODAL && this.state.countryToDelete) {
      return (
        <DeleteCountryModal
          country={this.state.countryToDelete}
          onDeleteSuccess={() => {}}
        />
      );
    }

    return null;
  }


  render() {
    if (!this.state.isInitialized) {
      return null;
    }
    return (
      <div className={styles.listCountries}>
        { this.renderHeader() }
        { this.renderTable() }
        { this.renderModals() }
      </div>
    );
  }

}


const mapStateToProps = (state) => (
  {
    countries              : selectors.countries(state),
    languageOptions        : selectors.languages(state),
    isFetchInProgress      : selectors.isFetchCountriesInProgress(state),
    isAddCountryInProgress : selectors.isAddCountryInProgress(state),
    isDeleteCountryProgress: selectors.isDeleteCountryProgress(state),
    hasAddCountryErrors    : selectors.hasAddCountryErrors(state),
    openModalId            : App.selectors.modal(state),
    canDelete              : Account.selectors.scope(state) === SCOPE_NAMES.SDN_ADMIN,
    canAdd                 : Account.selectors.scope(state) === SCOPE_NAMES.SDN_ADMIN,
  });


const mapDispatchToProps = (dispatch) => ({
  onOpenModal     : (modalId) => dispatch(App.actions.openModal(modalId)),
  onCloseModal    : () => dispatch(App.actions.closeModal()),
  onFetchCountries: () => dispatch(actions.fetchCountries()),
  onFetchLanguages: () => dispatch(actions.fetchLanguages()),
});


const ConnectedListCountries = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListCountries);


export default withStyles(styles)(ConnectedListCountries);
