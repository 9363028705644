exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2IQ9X{display:-ms-flexbox;display:flex}._182uw{height:calc(100% - 45px)}._2hD64{width:680px;width:68rem;padding:3.2rem;background:#fff;border-radius:8px;-webkit-box-shadow:0 4px 10px rgba(50,66,121,.05);box-shadow:0 4px 10px rgba(50,66,121,.05)}.hdoT3{position:relative;padding-bottom:1.6rem;margin-bottom:2.4rem}.hdoT3:after{content:\"\";position:absolute;bottom:0;width:144px;height:0;border-top:1px solid #e6e9e8}html[dir=ltr] .hdoT3:after{left:0}html[dir=rtl] .hdoT3:after{right:0}._8dYlC{width:40px;width:4rem;height:40px;height:4rem}html[dir=ltr] ._8dYlC{margin-right:2.4rem}html[dir=rtl] ._8dYlC{margin-left:2.4rem}._3rubn{width:160px;width:16rem;height:160px;height:16rem}", ""]);

// exports
exports.locals = {
	"wrapper": "_2IQ9X",
	"wrapper--center": "_182uw",
	"box": "_2hD64",
	"box__header": "hdoT3",
	"box__icon": "_8dYlC",
	"box__image": "_3rubn"
};