export const MODULE_ID = 'country';

export const MFA_WARNING_MODAL = `${MODULE_ID}/MFADisableWarningModal`;

export const ADD_COUNTRY_MODAL = `${MODULE_ID}/addCountryModal`;
export const ADD_COUNTRY_FORM = `${MODULE_ID}/addCountryForm`;

export const DELETE_COUNTRY_MODAL = `${MODULE_ID}/deleteCountryModal`;

export const ENABLE_COUNTRY_MODAL = `${MODULE_ID}/enableCountryModal`;

export const CONFIGURE_COUNTRY_GENERAL_TAB_FORM = `${MODULE_ID}/configureCountryGeneralTabForm`;
export const CONFIGURE_COUNTRY_UNITS_OF_MEASUREMENTS_TAB_FORM
    = `${MODULE_ID}/configureCountryUnitsOfMeasurementsTabForm`;
export const CONFIGURE_COUNTRY__GLUCOSE_RANGES_TAB_FORM
    = `${MODULE_ID}/configureCountryGlucoseRangesTabForm`;

export const CONFIGURE_COUNTRY_PAYERS_TAB_FORM = `${MODULE_ID}/configureCountryPayerForm`;
export const DELETE_PAYER_MODAL = `${MODULE_ID}/deletePayerModal`;
export const ADD_PAYER_MODAL = `${MODULE_ID}/addPayerModal`;
export const ADD_PAYER_FORM = `${MODULE_ID}/addPayerForm`;

export const CONFIGURE_COUNTRY_METERS_TAB_FORM = `${MODULE_ID}/configureCountryMetersForm`;

export const AVAILABLE_SCOPES = ['professional', 'clinicStaff', 'personal', 'caregiver'];

export const CONFIGURE_COUNTRY__IDENTIFIERS_TAB_FORM = `${MODULE_ID}/configureCountryIdentifiersForm`;

export const CUSTOMIZED_IDENTIFIERS_KEYS = [
  'accountIdentifiers.personalIdentifier',
  'accountIdentifiers.licence',
  'organizationIdentifiers.organizationIdentifier',
];

export const ADD_IDENTIFIER_OPTION_MODAL = `${MODULE_ID}/configureUpdateOrAddIdentifierOptionModal`;
export const ADD_IDENTIFIER_OPTION_FORM = `${MODULE_ID}/configureUpdateOrAddIdentifierOptionForm`;
export const SET_IDENTIFIER_OPTION_FORM_VALUE = `${MODULE_ID}/setUpdateOrAddIdentifierOptionFormValue`;

export const DIABETES_TYPES = ['Type1', 'Type2', 'Type3', 'PreDiabetes', 'GDM', 'NotSure', 'NoResponse', 'NotSet'];

export const UNITS_OF_MEASUREMENTS_OPTIONS = {
  bloodGlucoseConcentration: ['MG_DL', 'MMOL_L'],
  weight                   : ['KG', 'LB'],
  height                   : ['CM', 'IN'],
  calcium                  : ['MG_DL', 'MMOL_L'],
  cholesterol              : ['MG_DL', 'MMOL_L'],
  cockcroftGaultFiltration : ['ML_MIN'],
  creatinine               : ['MG_DL', 'UMOL_L'],
  filtration               : ['ML_MIN_1_73_M2'],
  hbA1C                    : ['PERCENT'],
  phosphorus               : ['MG_DL', 'MMOL_L'],
  potassium                : ['MG_DL', 'MMOL_L'],
  pressure                 : ['MMHG'],
  sodium                   : ['MG_DL', 'MMOL_L'],
  triglycerides            : ['MG_DL', 'MMOL_L'],
};

export const UNITS_SYMBOLS = {
  MG_DL         : 'mg/dL',
  MEQ_L         : 'mEq/L',
  MMOL_L        : 'mmol/L',
  UMOL_L        : '\u00b5mol/L',
  KG            : 'kg',
  LB            : 'lbs',
  CM            : 'cm',
  IN            : 'in',
  ML_MIN_1_73_M2: 'mL/min/1.73m\u00B2',
  ML_MIN        : 'mL/min',
  MMHG          : 'mmHg',
  PERCENT       : '%',
};

export const GLUCOSE_LEVELS_INPUTS_PRECISION = {
  MMOL_L: 2,
  MG_DL : 0,
};

/**
  Default precisions:
    toDisplay: 2
    toStorage: 3
 */
export const METRICS = {
  bloodGlucoseConcentration: {
    unit      : 'MG_DL',
    precisions: {
      toDisplay: 1,
    },
    conversions: {
      MMOL_L: {
        factor    : 0.0555,
        precisions: {
          toDisplay: 1,
        },
      },
    },
  },

};

export const IDENTIFIER_TERM_TYPES = {
  PERSONAL    : 'personalIdentifier',
  ORGANIZATION: 'organizationIdentifier',
  LICENCE     : 'licence',
};

export const ENABLE_COUNTRY_ERRORS = {
  firstDayOfWeek               : 'generalSettings.FirstDayOfWeek',
  termsOfServices              : 'generalSettings.TermsOfServiceURL',
  availableScopes              : 'generalSettings.Scopes',
  defaultGlucoseRanges         : 'default',
  countryMustBeAssignedToRegion: 'countryCode',
};
