exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RIaW7{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;gap:24px;padding-top:24px;border-top:1px solid #e0e8f2;margin-top:3.2rem}", ""]);

// exports
exports.locals = {
	"standardActions": "RIaW7"
};