exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".hkTBw{border-bottom:1px solid #e6e9e8;min-width:500px;min-width:50rem;max-width:800px;max-width:80rem}.hkTBw:first-child{margin-top:0}._2VFqV{border-bottom:none}._3ZvRm{color:#0d0d0d;cursor:pointer}.MzQH7{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:1.1rem}._1n0JH{line-height:2.6;margin:1.9rem 0 0}._3vfXb{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;height:32px;height:3.2rem;width:32px;width:3.2rem;background:#f6f6f6;border:none;border-radius:8px}._3jTRG{width:8px;width:.8rem;height:16px;height:1.6rem;fill:#0d0d0d;-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);-webkit-transition:background .3s linear,-webkit-transform .3s ease;transition:background .3s linear,-webkit-transform .3s ease;-o-transition:transform .3s ease,background .3s linear;transition:transform .3s ease,background .3s linear;transition:transform .3s ease,background .3s linear,-webkit-transform .3s ease}._3KomZ>._3jTRG{-webkit-transform:rotate(270deg);-ms-transform:rotate(270deg);transform:rotate(270deg)}._2eeHP{overflow:hidden}", ""]);

// exports
exports.locals = {
	"section": "hkTBw",
	"section--open": "_2VFqV",
	"section__switch": "_3ZvRm",
	"section__header": "MzQH7",
	"section__header__text": "_1n0JH",
	"section__header__switchIndicator": "_3vfXb",
	"section__header__switchIndicator__icon": "_3jTRG",
	"section__header__switchIndicator--expanded": "_3KomZ",
	"section__contentContainer": "_2eeHP"
};