import App from 'modules/App';
import Account from 'modules/Account';
import Country from 'modules/Country';


export function getCoreSagas() {
  return {
    [App.constants.MODULE_ID]    : App.sagas,
    [Account.constants.MODULE_ID]: Account.sagas,
    [Country.constants.MODULE_ID]: Country.sagas,
  };
}
