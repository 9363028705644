import { MODULE_ID } from './constants';


export const FETCH_COUNTRIES = `${MODULE_ID}/FETCH_COUNTRIES`;
export const FETCH_COUNTRIES_SUCCESS = `${MODULE_ID}/FETCH_COUNTRIES_SUCCESS`;
export const FETCH_COUNTRIES_ERROR = `${MODULE_ID}/FETCH_COUNTRIES_ERROR`;

export const FETCH_LANGUAGES = `${MODULE_ID}/FETCH_LANGUAGES`;
export const FETCH_LANGUAGES_SUCCESS = `${MODULE_ID}/FETCH_LANGUAGES_SUCCESS`;
export const FETCH_LANGUAGES_ERROR = `${MODULE_ID}/FETCH_LANGUAGES_ERROR`;

export const ADD_COUNTRY = `${MODULE_ID}/ADD_COUNTRY`;
export const ADD_COUNTRY_SUCCESS = `${MODULE_ID}/ADD_COUNTRY_SUCCESS`;
export const ADD_COUNTRY_ERROR = `${MODULE_ID}/ADD_COUNTRY_ERROR`;

export const ENABLE_COUNTRY = `${MODULE_ID}/ENABLE_COUNTRY`;
export const ENABLE_COUNTRY_SUCCESS = `${MODULE_ID}/ENABLE_COUNTRY_SUCCESS`;
export const ENABLE_COUNTRY_ERROR = `${MODULE_ID}/ENABLE_COUNTRY_ERROR`;
export const ENABLE_COUNTRY_CLEAR = `${MODULE_ID}/ENABLE_COUNTRY_CLEAR`;


export const DELETE_COUNTRY = `${MODULE_ID}/DELETE_COUNTRY`;
export const DELETE_COUNTRY_SUCCESS = `${MODULE_ID}/DELETE_COUNTRY_SUCCESS`;
export const DELETE_COUNTRY_ERROR = `${MODULE_ID}/DELETE_COUNTRY_ERROR`;

export const FETCH_COUNTRY = `${MODULE_ID}/FETCH_COUNTRY`;
export const FETCH_COUNTRY_SUCCESS = `${MODULE_ID}/FETCH_COUNTRY_SUCCESS`;
export const FETCH_COUNTRY_ERROR = `${MODULE_ID}/FETCH_COUNTRY_ERROR`;

export const FETCH_ALL_COUNTRIES = `${MODULE_ID}/FETCH_ALL_COUNTRIES`;
export const FETCH_ALL_COUNTRIES_SUCCESS = `${MODULE_ID}/FETCH_ALL_COUNTRIES_SUCCESS`;
export const FETCH_ALL_COUNTRIES_ERROR = `${MODULE_ID}/FETCH_ALL_COUNTRIES_ERROR`;

export const FETCH_COUNTRY_CONFIGURATION = `${MODULE_ID}/FETCH_COUNTRY_CONFIGURATION`;
export const FETCH_COUNTRY_CONFIGURATION_SUCCESS = `${MODULE_ID}/FETCH_COUNTRY_CONFIGURATION_SUCCESS`;
export const FETCH_COUNTRY_CONFIGURATION_ERROR = `${MODULE_ID}/FETCH_COUNTRY_CONFIGURATION_ERROR`;

export const SET_IDENTIFIER_OPTION_FORM_VALUE = `${MODULE_ID}/SET_IDENTIFIER_OPTION_FORM_VALUE`;
export const SET_UPDATE_OR_ADD_IDENTIFIER_TERM = `${MODULE_ID}/SET_UPDATE_OR_ADD_IDENTIFIER_TERM`;

export const UPDATE_COUNTRY_CONFIGURATION_GENERAL = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_GENERAL`;
export const UPDATE_COUNTRY_CONFIGURATION_GENERAL_SUCCESS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_GENERAL_SUCCESS`;
export const UPDATE_COUNTRY_CONFIGURATION_GENERAL_ERROR = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_GENERAL_ERROR`;

export const UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS`;
export const UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS_SUCCESS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS_SUCCESS`;
export const UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS_ERROR = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS_ERROR`;


export const UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES`;
export const UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES_SUCCESS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES_SUCCESS`;
export const UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES_ERROR = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES_ERROR`;

export const UPDATE_COUNTRY_CONFIGURATION_PAYERS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_PAYERS`;
export const UPDATE_COUNTRY_CONFIGURATION_PAYERS_SUCCESS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_PAYERS_SUCCESS`;
export const UPDATE_COUNTRY_CONFIGURATION_PAYERS_ERROR = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_PAYERS_ERROR`;

export const UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS`;
export const UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS_SUCCESS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS_SUCCESS`;
export const UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS_ERROR = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS_ERROR`;

export const UPDATE_COUNTRY_CONFIGURATION_METERS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_METERS`;
export const UPDATE_COUNTRY_CONFIGURATION_METERS_SUCCESS = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_METERS_SUCCESS`;
export const UPDATE_COUNTRY_CONFIGURATION_METERS_ERROR = `${MODULE_ID}/UPDATE_COUNTRY_CONFIGURATION_METERS_ERROR`;
