import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import lowerCase from 'lodash/lowerCase';
import isEqual from 'lodash/isEqual';
import cn from 'classnames';
import AddIcon from 'svg/add.svg';
import RemoveIcon from 'svg/remove.svg';
import Loupe from 'svg/loupe-light.svg';
import Input from 'components/Form/Input';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import StandardActions from 'components/Form/StadardActions';
import FormContainerAbstract from 'components/FormContainerAbstract';
import App from 'modules/App';
import Table from 'components/Table';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';
import messages from '../../../../messages';
import * as constants from '../../../../constants';
import validatorRules from './validatorRules.json';
import styles from './Meters.pcss';


class MetersForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    isFetchInProgress : PropTypes.bool.isRequired,
    // Explicit actions
    onClose           : PropTypes.func,
    // Implicit props
    isUpdateInProgress: PropTypes.bool.isRequired,

    configurationMeters: PropTypes.object,
  };


  constructor(props) {
    super(props);
    this.state = {
      formInitialState: JSON.parse(JSON.stringify({
        assignedMeters  : this.props.assignedMeters,
        unassignedMeters: this.props.unassignedMeters,
      })),
      hasUnsavedChanges : false,
      assignedMeters    : [...this.props.assignedMeters] || [],
      unassignedMeters  : [...this.props.unassignedMeters] || [],
      shouldReset       : false,
      isSearchProcessing: false,
    };
    this.validatorRules = validatorRules;
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.assignedMeters.length !== this.state.assignedMeters.length
      || prevState.unassignedMeters.length !== this.state.unassignedMeters.length
    ) {
      this.setFormStatus();
    }

    if (prevState.search !== this.state.search) {
      this.onSearchFinish();
    }
    if (prevProps.isUpdateInProgress && !this.props.isUpdateInProgress) {
      this.onSetShouldReset();
    }
    if (this.state.shouldReset && !this.props.isFetchInProgress) {
      this.onInitialize();
    }
  }

  onInitialize() {
    this.setState({
      formInitialState: JSON.parse(JSON.stringify({
        assignedMeters  : this.props.assignedMeters,
        unassignedMeters: this.props.unassignedMeters,
      })),
      hasUnsavedChanges : false,
      assignedMeters    : [...this.props.assignedMeters] || [],
      unassignedMeters  : [...this.props.unassignedMeters] || [],
      shouldReset       : false,
      isSearchProcessing: false,
    });
  }


  onSetShouldReset() {
    this.setState({ shouldReset: true });
  }


  onSubmit() {
    const devices = (this.state.assignedMeters || []).map((x) => x.sku);
    this.props.onSubmit(
      {
        devices,
      },
    );
  }


  onSearchFinish() {
    this.setState((state) => ({
      isSearchProcessing: state.isSearchProcessing,
    }));
  }


  onReset() {
    this.setState({
      assignedMeters   : [...this.state.formInitialState.assignedMeters],
      unassignedMeters : [...this.state.formInitialState.unassignedMeters],
      hasUnsavedChanges: false,
    });
    this.props.onFormErrors(null);
  }


  setFormStatus() {
    const hasUnsavedChanges = !isEqual(this.state.formInitialState.assignedMeters, this.state.assignedMeters)
    || !isEqual(
      this.state.formInitialState.unassignedMeters.map((x) => x.sku).sort(),
      this.state.unassignedMeters.map((x) => x.sku).sort(),
    );

    this.setState({ hasUnsavedChanges });
    this.props.onHasUnsavedChangesChanged(hasUnsavedChanges);
  }


  get baseSchema() {
    return [
      {
        key         : 'name',
        labelMessage: messages.labels.meterName,
        renderer    : (meter) => (<b>{ meter.name }</b>),
      },
      {
        key         : 'sku',
        labelMessage: messages.labels.metersSku,
        renderer    : (meter) => (<p>{ meter.sku }</p>),
      },
    ];
  }


  get assignedSchema() {
    return [...this.baseSchema,
      {
        key     : 'action',
        renderer: (meter) => this.renderUnassignAction(meter),
      }];
  }


  get unassignedSchema() {
    return [...this.baseSchema,
      {
        key     : 'action',
        renderer: (meter) => this.renderAssignAction(meter),
      }];
  }


  get filteredAssignedEntities() {
    const entities = this.state.search
      ? this.filterEntities(this.state.assignedMeters, this.state.search)
      : this.state.assignedMeters;
    return this.orderSearch(entities);
  }


  get filteredUnassignedEntities() {
    const entities = this.state.search
      ? this.filterEntities(this.state.unassignedMeters, this.state.search)
      : this.state.unassignedMeters;
    return this.orderSearch(entities);
  }


  onAssignMeter(meter) {
    this.setState((state) => {
      const unassignedMeters = filter(this.state.unassignedMeters,
        (unassignedMeter) => unassignedMeter.sku !== meter.sku);
      return (
        {
          assignedMeters: [...state.assignedMeters, meter],
          unassignedMeters,
        });
    });
  }


  onUnassignMeter(meter) {
    this.setState((state) => {
      const assignedMeters = filter(this.state.assignedMeters,
        (unassignedMeter) => unassignedMeter.sku !== meter.sku);
      return (
        {
          assignedMeters,
          unassignedMeters: [...state.unassignedMeters, meter],
        });
    });
  }


  filterEntities(entities, searchValue) {
    const value = lowerCase(searchValue);
    return filter(entities, (entity) => lowerCase(entity.name).includes(value)
      || lowerCase(entity.sku).includes(value));
  }


  orderSearch(entities) {
    return entities.sort((a, b) => {
      if (lowerCase(a.name) > lowerCase(b.name)) { return 1; }
      if (lowerCase(a.name) < lowerCase(b.name)) { return -1; }
      return -1;
    });
  }


  renderAssignAction(meter) {
    return (
      <Button
        className={cn(styles.table__cell__actionButton, 'btn--primary', 'btn--filled')}
        onClick={() => this.onAssignMeter(meter)}
      >
        <AddIcon />
      </Button>
    );
  }


  renderUnassignAction(meter) {
    return (
      <Button
        className={cn(styles.table__cell__actionButton, 'btn--filled')}
        onClick={() => this.onUnassignMeter(meter)}
      >
        <RemoveIcon />
      </Button>
    );
  }


  renderSearch() {
    return (
      <div className={styles.header__search}>
        <Input
          placeholder={messages.placeholders.searchMeters}
          value={this.state.search}
          onChange={
            (input) => this.setState({ isSearchProcessing: true,
              search            : input.value })
          }
        />
        <Loupe className={styles.search__loupe} />
      </div>
    );
  }


  renderAssignedTable() {
    return (
      <div className={cn(styles.table, styles.table__assigned)}>
        <h5 className="text--h5 mt-0"><FormattedMessage {...messages.headers.metersAssignedToCountry} /></h5>
        <Table
          idKey="deviceId"
          schema={this.assignedSchema}
          entities={this.filteredAssignedEntities || []}
          perPage={10}
          isPerPageOff
        />
        {
          !!this.state.assignedMeters.length || (
            <>
              <p className={cn(styles.table__assigned__noneMetersAssignedToCountry, 'text--paragraph')}><FormattedMessage
                {...messages.infos.noneMetersAssignedToCountry}
                values={
                  {
                    b: (chunks) => <b>{ chunks }</b>,
                  }
                }
              />
              </p>
              <p className="text--paragraph mt-7"><FormattedMessage
                {...messages.infos.emptyCountryWillAssignAll}
              />
              </p>
            </>
          )
        }
      </div>
    );
  }


  renderUnassignedTable() {
    return (
      <div className={cn(styles.table)}>
        <h5 className="text--h5 mt-0"><FormattedMessage {...messages.headers.metersUnassignedToCountry} /></h5>
        <Table
          idKey="deviceId"
          schema={this.unassignedSchema}
          entities={this.filteredUnassignedEntities}
          perPage={10}
          isPerPageOff
        />
      </div>
    );
  }


  render() {
    return (
      <Form
        onSubmit={() => this.onSubmit()}
        onReset={() => this.onReset()}
      >
        { this.renderSearch() }
        <div className={styles.tables}>
          { this.renderAssignedTable() }
          { this.renderUnassignedTable() }
        </div>
        <StandardActions
          hasChanges={this.state.hasUnsavedChanges}
          isInProgress={this.props.isFetchInProgress || this.props.isUpdateInProgress}
          infoMessage={this.props.isConfiguredCountryActive ? messages.infos.saveOnActiveCountry : null}
        />
      </Form>
    );
  }

}

const mapStateToProps = (state) => {
  const assignedMeters = [];
  const unassignedMeters = [];

  const countryDevicesSKU = selectors.devices(state);
  const allDevices = App.selectors.devices(state);

  forEach(allDevices, (device) => {
    if (countryDevicesSKU.includes(device.sku)) {
      assignedMeters.push(device);
    } else {
      unassignedMeters.push(device);
    }
  });

  return ({
    formValues               : App.selectors.formSelector(constants.CONFIGURE_COUNTRY_METERS_TAB_FORM)(state),
    isUpdateInProgress       : selectors.isUpdateCountryConfigurationMetersInProgress(state),
    isConfiguredCountryActive: selectors.isConfiguredCountryActive(state),
    assignedMeters,
    unassignedMeters,
  });
};


const mapDispatchToProps = (dispatch) => {
  const formName = constants.CONFIGURE_COUNTRY_METERS_TAB_FORM;
  return {
    onSubmit        : (values) => dispatch(actions.updateCountryConfigurationMeters(values)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue(formName, input)),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues(formName, values)),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors(formName, errors)),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedMetersForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MetersForm);


export default withStyles(styles)(ConnectedMetersForm);
