import React, { FC } from 'react';


interface Props {
  status: 'success' | 'error',
}


const StatusDot: FC<Props> = ({ status, children }) => (
  <span className={`statusDot statusDot--${status}`}>{ children }</span>
);

export default StatusDot;
