import React, { FC, useEffect, useRef, useState } from 'react';


interface Props {
  tooltipText: string,
  tooltipId?: string,
}


const EllipsisTooltip: FC<Props> = ({ tooltipText, tooltipId = 'globalTooltip', children }) => {
  const ref = useRef(null);

  const [isHidden, setIsHidden] = useState<boolean>(true);

  const checkTextOverflow = () => {
    const content = ref.current;
    if (content) {
      const isOverflowing = content.scrollWidth > content.offsetWidth;
      setIsHidden(!isOverflowing);
    }
  };

  useEffect(() => {
    checkTextOverflow();
    window.addEventListener('resize', checkTextOverflow);
    return () => {
      window.removeEventListener('resize', checkTextOverflow);
    };
  }, []);

  return (
    <div
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipText}
      data-tooltip-hidden={isHidden}
      data-tooltip-place="top"
    >
      <div className="table__wrapCell" ref={ref}>
        { children }
      </div>
    </div>
  );
};

export default EllipsisTooltip;
