import get from 'lodash/get';
import find from 'lodash/find';
import { createSelector } from 'reselect';
import { APP_LOCALE_LANGUAGES_MAP } from 'localeConstants';
import * as constants from './constants';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const routerSelector = (state) => state.router;


/**
 * Selects if client re-renders app after cold start
 */
export const isClientInitialized = createSelector(
  mainSelector,
  (state) => state.isClientInitialized,
);


/**
 * Selects current region name
 */
export const regionName = createSelector(
  mainSelector,
  (state) => state.regionName,
);


/**
 * Selects original region name
 */
export const originalRegionName = createSelector(
  mainSelector,
  (state) => state.originalRegionName,
);


/**
 * Selects html direction
 */
export const direction = createSelector(
  mainSelector,
  (state) => state.direction,
);


/**
 * Selects locale setting
 */
export const locale = createSelector(
  mainSelector,
  (state) => state.locale,
);


/**
 * Selects language code setting
 */
export const langCode = createSelector(
  mainSelector,
  (state) => (APP_LOCALE_LANGUAGES_MAP[state.locale] || state.locale),
);

/**
 * Selects translations for the language code
 */
export const translations = createSelector(
  mainSelector,
  (state) => state.translations,
);


/**
 * Devices list
 */
export const devices = createSelector(
  mainSelector,
  (state) => state.devices,
);


/**
 * Devices case types list
 */
export const caseTypes = createSelector(
  mainSelector,
  (state) => state.caseTypes,
);


/**
 * Languages dictionary
 */
export const languages = createSelector(
  mainSelector,
  (state) => state.languages,
);


/**
 * Localization resources
 */
export const localizationResources = createSelector(
  mainSelector,
  (state) => state.localizationResources,
);


/**
 * Alerts selector
 */
export const alerts = createSelector(
  mainSelector,
  (state) => state.alerts,
);


/**
 * System alerts selector
 */
export const systemAlerts = createSelector(
  mainSelector,
  (state) => state.systemAlerts,
);


/**
 * System alerts settings selector
 */
export const systemAlertsSettings = createSelector(
  mainSelector,
  (state) => state.systemAlertsSettings,
);


/**
 * Alerts that are generated before time threshold are obsolete
 */
export const alertsTimeThreshold = createSelector(
  mainSelector,
  (state) => state.alertsTimeThreshold,
);


/**
 * Dismissed alerts by alert name
 */
export const dismissedAlerts = createSelector(
  mainSelector,
  (state) => state.dismissedAlerts,
);

export const shouldDismissAllAlerts = createSelector(
  mainSelector,
  (state) => state.shouldDismissAllAlerts,
);

/**
 * Forms states
 */
export const formSelector = (formName) => createSelector(
  mainSelector,
  (state) => get(state.forms, formName, null),
);


/**
 * Select dropdown state
 */
export const dropdown = createSelector(
  mainSelector,
  (state) => state.openDropdownId,
);


/**
 * Print mode
 */
export const printMode = createSelector(
  mainSelector,
  (state) => state.printMode,
);


/**
 * Current route data
 */
export const route = createSelector(
  mainSelector,
  (state) => state.route,
);


/**
 * Select modal state
 */
export const modal = createSelector(
  mainSelector,
  (state) => state.openModalId,
);


/**
 * Select floating modal state
 */
export const floatingModalSelector = (floatingModalId) => createSelector(
  mainSelector,
  (state) => find(state.openFloatingModals, { floatingModalId }, null),
);


/**
 * Select active floating modal
 */
export const activeFloatingModalId = createSelector(
  mainSelector,
  (state) => state.activeFloatingModalId,
);


/**
 * Feature toggles
 */
export const featureToggles = createSelector(
  mainSelector,
  (state) => state.featureToggles,
);


export const signalRActiveNotifications = createSelector(
  mainSelector,
  (state) => state.signalRActiveNotifications,
);


export const isNavigationBlocked = createSelector(
  mainSelector,
  (state) => state.isNavigationBlocked,
);


export const isSignalRConnected = createSelector(
  mainSelector,
  (state) => state.isSignalRConnected,
);


/**
 * Websocket state
 */
export const websocketStateSelector = () => createSelector(
  mainSelector,
  (state) => state.wsState,
);

export const routerPathName = createSelector(
  routerSelector,
  (state) => state.pathname,
);

export const routerQueries = createSelector(
  routerSelector,
  (state) => state.queries,
);

export const routerSearch = createSelector(
  routerSelector,
  (state) => state.search,
);

