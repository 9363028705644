import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AppContext } from 'context';
// import CheckCircle from 'svg/check-circle.svg';
import Statement from 'components/Statement';
import messages from '../../messages';
import styles from '../messagePage.pcss';


class SignOutSuccessStatement extends React.PureComponent {

  static contextType = AppContext;


  get signInUrl() {
    return this.context.getUrl('login');
  }


  // get headerIcon() {
  //   return <CheckCircle className={styles.headerIcon} />;
  // }
  //
  //
  // get messageHeader() {
  //   return <FormattedMessage {...messages.headers.seeYouLater} />;
  // }


  get messageDetails() {
    const clickHereToSignInAgain = (chunk) => (
      <a href={this.signInUrl}>
        { chunk }
      </a>
    );

    return (
      <FormattedMessage
        {...messages.infos.loggingOutSuccessful}
        values={{ clickHereToSignInAgain }}
      />
    );
  }


  render() {

    return (
      <Statement
        headerMessage={messages.headers.seeYouLater}
        imgSrc="/bo/assets/svg/illustration/logout.svg"
        withIcon="success"
      >
        { this.messageDetails }
      </Statement>
    );
  }

}


export default withStyles(styles)(SignOutSuccessStatement);
