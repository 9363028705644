import React, { FC } from 'react';
import cn from 'classnames';
import DeleteIcon from 'svg/delete.svg';


interface Props {
  entity: unknown,
  isDisabled?: boolean,
  onDelete: (entity: unknown) => void,
}


const DeleteButton: FC<Props> = ({ entity, isDisabled = false, onDelete }) => {
  const onClick = () => !isDisabled && onDelete(entity);
  return (
    <DeleteIcon
      className={
        cn('dataTable__deleteIcon', {
          'dataTable__deleteIcon--inactive': isDisabled,
        })
      }
      onClick={onClick}
    />
  );
};

export default DeleteButton;
