import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'hooks';
import * as actions from './actions';
import * as selectors from './selectors';


export const useCountryOptions = () => {
  const countries = useSelector(selectors.countries);
  const fetchCountries = useAction(actions.fetchCountries);

  useEffect(() => {
    fetchCountries();
  }, []);

  return countries
    .map((c) => ({ label: c.englishName, value: c.code }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const useAllCountryOptions = () => {
  const allCountries = useSelector(selectors.allCountries);
  const fetchAllCountries = useAction(actions.fetchAllCountries);

  useEffect(() => {
    fetchAllCountries();
  }, []);

  return allCountries.map((c) => ({ label: c.englishName, value: c.code }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const useLanguageOptions = () => {
  const languages = useSelector(selectors.languages);
  const fetchLanguages = useAction(actions.fetchLanguages);

  useEffect(() => {
    fetchLanguages();
  }, []);

  return languages
    .map((l) => ({ label: l.englishName, value: l.code }))
    .sort((a, b) => a.label.localeCompare(b.label));
};
