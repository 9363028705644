import * as actionTypes from './actionTypes';


export const fetchCountries = () => ({
  type: actionTypes.FETCH_COUNTRIES,
});


export const fetchCountriesSuccess = (countries) => ({
  type   : actionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: {
    countries,
  },
});


export const fetchCountriesError = (error) => ({
  type: actionTypes.FETCH_COUNTRIES_ERROR,
  error,
});


export const fetchLanguages = () => ({
  type: actionTypes.FETCH_LANGUAGES,
});


export const fetchLanguagesSuccess = (languages) => ({
  type   : actionTypes.FETCH_LANGUAGES_SUCCESS,
  payload: {
    languages,
  },
});


export const fetchLanguagesError = (error) => ({
  type: actionTypes.FETCH_LANGUAGES_ERROR,
  error,
});


export const addCountry = ({ countryCode, countryName, language }) => ({
  type   : actionTypes.ADD_COUNTRY,
  payload: {
    countryCode,
    countryName,
    language,
  },
});


export const addCountrySuccess = (languages) => ({
  type   : actionTypes.ADD_COUNTRY_SUCCESS,
  payload: {
    languages,
  },
});


export const addCountryError = (error) => ({
  type: actionTypes.ADD_COUNTRY_ERROR,
  error,
});


export const enableCountry = (countryCode) => ({
  type   : actionTypes.ENABLE_COUNTRY,
  payload: {
    countryCode,
  },
});


export const enableCountrySuccess = (enableCountryErrors) => ({
  type   : actionTypes.ENABLE_COUNTRY_SUCCESS,
  payload: {
    enableCountryErrors,
  },
});


export const enableCountryError = (errors) => ({
  type   : actionTypes.ENABLE_COUNTRY_ERROR,
  payload: {
    errors,
  },
});


export const enableCountryClear = (countryCode) => ({
  type   : actionTypes.ENABLE_COUNTRY_CLEAR,
  payload: {
    countryCode,
  },
});


export const deleteCountry = (countryCode) => ({
  type   : actionTypes.DELETE_COUNTRY,
  payload: {
    countryCode,
  },
});


export const deleteCountrySuccess = (isDeleteCountryResultSuccess) => ({
  type   : actionTypes.DELETE_COUNTRY_SUCCESS,
  payload: {
    isDeleteCountryResultSuccess,
  },
});


export const deleteCountryError = (error) => ({
  type: actionTypes.DELETE_COUNTRY_ERROR,
  error,
});


export const fetchCountry = (countryCode) => ({
  type   : actionTypes.FETCH_COUNTRY,
  payload: {
    countryCode,
  },
});


export const fetchCountrySuccess = (country) => ({
  type   : actionTypes.FETCH_COUNTRY_SUCCESS,
  payload: {
    configuredCountry: country,
  },
});


export const fetchCountryError = (error) => ({
  type: actionTypes.FETCH_COUNTRY_ERROR,
  error,
});


export const fetchAllCountries = () => ({
  type: actionTypes.FETCH_ALL_COUNTRIES,
});


export const fetchAllCountriesSuccess = (countries) => ({
  type   : actionTypes.FETCH_ALL_COUNTRIES_SUCCESS,
  payload: {
    countries,
  },
});


export const fetchAllCountriesError = (error) => ({
  type: actionTypes.FETCH_ALL_COUNTRIES_ERROR,
  error,
});


export const fetchCountryConfiguration = (countryCode) => ({
  type   : actionTypes.FETCH_COUNTRY_CONFIGURATION,
  payload: {
    countryCode,
  },
});


export const fetchCountryConfigurationSuccess = (countryConfiguration) => ({
  type   : actionTypes.FETCH_COUNTRY_CONFIGURATION_SUCCESS,
  payload: {
    countryConfiguration,
  },
});


export const fetchCountryConfigurationError = (error) => ({
  type: actionTypes.FETCH_COUNTRY_CONFIGURATION_ERROR,
  error,
});


export const setIdentifierOptionFormValue = (initialFormValue) => ({
  type   : actionTypes.SET_IDENTIFIER_OPTION_FORM_VALUE,
  payload: {
    initialFormValue,
  },
});

export const setUpdateOrAddIdentifierTerm = (term) => ({
  type   : actionTypes.SET_UPDATE_OR_ADD_IDENTIFIER_TERM,
  payload: {
    term,
  },
});

export const updateCountryConfigurationGeneral = (configurationGeneral) => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL,
  payload: {
    configurationGeneral,
  },
});


export const updateCountryConfigurationGeneralSuccess = () => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL_SUCCESS,
  payload: {
  },
});


export const updateCountryConfigurationGeneralError = (error) => ({
  type: actionTypes.UPDATE_COUNTRY_CONFIGURATION_GENERAL_ERROR,
  error,
});


export const updateCountryConfigurationUnitsOfMeasurements = (unitMeasurements) => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS,
  payload: {
    unitMeasurements,
  },
});


export const updateCountryConfigurationUnitsOfMeasurementsSuccess = () => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS_SUCCESS,
  payload: {
  },
});


export const updateCountryConfigurationUnitsOfMeasurementsError = (error) => ({
  type: actionTypes.UPDATE_COUNTRY_CONFIGURATION_UNIT_OF_MEASUREMENTS_ERROR,
  error,
});


export const updateCountryConfigurationGlucoseRanges = (glucoseRanges) => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES,
  payload: {
    glucoseRanges,
  },
});


export const updateCountryConfigurationGlucoseRangesSuccess = () => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES_SUCCESS,
  payload: {
  },
});


export const updateCountryConfigurationGlucoseRangesError = (error) => ({
  type: actionTypes.UPDATE_COUNTRY_CONFIGURATION_GLUCOSE_RANGES_ERROR,
  error,
});

export const updateCountryConfigurationPayers = (payers) => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS,
  payload: {
    payers,
  },
});


export const updateCountryConfigurationPayersSuccess = () => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS_SUCCESS,
  payload: {

  },
});


export const updateCountryConfigurationPayersError = (error) => ({
  type: actionTypes.UPDATE_COUNTRY_CONFIGURATION_PAYERS_ERROR,
  error,
});


export const updateCountryConfigurationIdentifiers = (identifiers) => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS,
  payload: {
    identifiers,
  },
});


export const updateCountryConfigurationIdentifiersSuccess = () => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS_SUCCESS,
  payload: {

  },
});


export const updateCountryConfigurationIdentifiersError = (error) => ({
  type: actionTypes.UPDATE_COUNTRY_CONFIGURATION_IDENTIFIERS_ERROR,
  error,
});


export const updateCountryConfigurationMeters = (devices) => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS,
  payload: {
    devices,
  },
});


export const updateCountryConfigurationMetersSuccess = () => ({
  type   : actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS_SUCCESS,
  payload: {

  },
});


export const updateCountryConfigurationMetersError = (error) => ({
  type: actionTypes.UPDATE_COUNTRY_CONFIGURATION_METERS_ERROR,
  error,
});
