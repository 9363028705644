export const MODULE_ID = 'app';

export const SIGNALR_MODULE_ID = 'app-signalR';
export const SIGNALR_HUB_NAME = 'publishLogHub';
export const SIGNALR_NOTIFICATION_RECEIVE_MSG = 'Notification';

export const TRANSLATIONS_LOCATIONS = [
  'components/Form',
  'modules/App',
  'modules/Account',
  'modules/Layout',
  'modules/Country',
  'modules/BusinessLogicSystem',
  'modules/Organization',
  'modules/OrganizationApprover',
  'modules/PublishSnapshot',
];

export const CONTACT_EMAIL = 'support@glucocontro.online';

export const LANGUAGE_SELECTOR_DROPDOWN = `${MODULE_ID}/languageSelectorDropdown`;

export const FEATURE_TOGGLES = {
  featureCgm: 'featureCgm',
};

export const LOGIN_REDIRECT_TO_KEY = 'loginRedirectToPath';

export const WEEK_DAYS = {
  Monday   : 'Monday',
  Tuesday  : 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday : 'Thursday',
  Friday   : 'Friday',
  Saturday : 'Saturday',
  Sunday   : 'Sunday',
};

export const UNSAVED_CHANGES_MODAL = `${MODULE_ID}/unsavedChangesModal`;
